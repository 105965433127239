import React, { useState } from "react";
// import { Row, Col, FormGroup, Input, Label, FormFeedback } from "reactstrap";
import Input from "Components/FutureVersion/AboutusComponents/NewsLetterModal/Input";
import SelectOfferingModal from "../Modals/SelectOffering";
import SuccessModal from "../Modals/Success";

const ErrorMessage = ({ message, visible }) => {
  if (visible)
    return (
      <div style={{ color: "#FF1606" }} className=" text-sm">
        {message}
      </div>
    );
  return <></>;
};

const reasonToReachOptions = [
  { id: 1, label: "Give Feedback to Us" },
  { id: 2, label: "Issues related to your account" },
  { id: 3, label: "Want to know more about an Offering" },
  { id: 4, label: "Speak to a Caarya coordinators" },
  { id: 5, label: "Other" },
];

const offeringLables = [
  null,
  "Career Kickstarter",
  "Communities",
  "Work-Study",
  "Industry Safari",
];

const HelpCenterFrom = () => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [reason, setReason] = useState(1);
  const [offering, setOffering] = useState();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showOfferings, setShowOfferings] = useState(false);

  console.log("Data", data);

  const handleSubmit = async () => {
    try {
      const validationErrors = getValidationError(data);
      if (validationErrors) {
        setErrors(validationErrors);
        return;
      }
      let body = {
        data,
        reason: reasonToReachOptions[reason - 1]?.label,
      };
      if (offering) {
        body["offering"] = offeringLables[offering];
      }
      // const response = await ContactAPI.helpCenterQuestion(body);
      setErrors({});
      setShowSuccess(true);
      setData({});
      setReason(1);
    } catch (err) {
      console.log("Submit error", err);
    }
  };

  const getValidationError = (data) => {
    let error = {};
    if (reason === 1 || reason === 5) {
      if (!data.message) error["message"] = true;
    } else if (reason === 2) {
      if (!data.name) error["name"] = true;
      if (!data.email) error["email"] = true;
      if (!data.message) error["message"] = true;
    } else if (reason === 3) {
      if (!data.name) error["name"] = true;
      if (!data.email) error["email"] = true;
      if (!data.message) error["message"] = true;
    } else if (reason === 4) {
      if (!data.name) error["name"] = true;
      if (!data.email) error["email"] = true;
    }
    return Object.keys(error).length === 0 ? null : error;
  };

  React.useEffect(() => {
    setErrors({});

    if (reason === 3) {
      console.log("Check");
      setShowOfferings(true);
    }

    if (reason !== 3) {
      setOffering(0);
    }
  }, [reason]);

  return (
    <div className=" help-center-form">
      <SuccessModal
        isOpen={showSuccess}
        closeModal={() => setShowSuccess(false)}
      />
      <SelectOfferingModal
        onSelect={setOffering}
        isOpen={showOfferings}
        closeModal={() => setShowOfferings(false)}
      />
      <div className="poppins shadow-xl card ">
        <div className=" m-8 py-8 px-3">
          <div className="fields flex flex-col gap-6">
            <div className="flex flex-col ">
              <label>Reason to reach out to Us</label>
              <div className="bg-gray-100 rounded-lg border  mt-1 p-2 ">
                <select
                  type="select"
                  className=" outline-none focus:ring-transparent-none w-full bg-transparent"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                >
                  {reasonToReachOptions.map((e) => (
                    <option value={e.id}>{`${e.label} ${
                      e.id === 3 && offering
                        ? `(${offeringLables[offering]})`
                        : ""
                    }`}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex gap-6 w-full ">
              <div className="flex flex-col w-full">
                <label>
                  Your Name
                  <span className=" form-optional font-medium">
                    {`${reason === 1 || reason === 5 ? "(optional)" : ""}`}
                  </span>
                </label>
                <Input
                  value={data.name || ""}
                  type="text"
                  placeholder="Enter your name"
                  invalid={errors.name}
                  onChange={(e) =>
                    setData((state) => ({
                      ...state,
                      name: e.target.value,
                    }))
                  }
                />
                <ErrorMessage
                  message={"Please enter your name"}
                  visible={errors.name}
                />
              </div>
              <div className="flex flex-col w-full">
                <label>
                  Email Address
                  <span className=" form-optional font-medium">
                    {`${reason === 1 || reason === 5 ? "(optional)" : ""}`}
                  </span>
                </label>
                <Input
                  value={data.email || ""}
                  type="text"
                  placeholder="Email Address"
                  invalid={errors.email}
                  onChange={(e) =>
                    setData((state) => ({
                      ...state,
                      email: e.target.value,
                    }))
                  }
                />
                <ErrorMessage
                  message={"Please enter your email"}
                  visible={errors.email}
                />
              </div>
            </div>

            {reason === 5 && (
              <div className="flex flex-col">
                <label>Subject</label>
                <Input
                  value={data.subject || ""}
                  type="text"
                  placeholder="Subject"
                  invalid={errors.subject}
                  onChange={(e) =>
                    setData((state) => ({
                      ...state,
                      subject: e.target.value,
                    }))
                  }
                />
                <ErrorMessage
                  message={"Please enter your subject"}
                  visible={errors.subject}
                />
              </div>
            )}

            <div className="flex flex-col">
              <label>
                Your Message/Query
                <span className=" form-optional font-medium">{`${
                  reason === 4 ? "(optional)" : ""
                }`}</span>
              </label>
              <Input
                value={data.message || ""}
                onChange={(e) =>
                  setData((state) => ({
                    ...state,
                    message: e.target.value,
                  }))
                }
                type="textarea"
                placeholder="Write your message/query"
                invalid={errors.message}
              />
              <ErrorMessage
                message={"Please enter a message/query"}
                visible={errors.message}
              />
            </div>
          </div>
          <div className="mt-5">
            <button
              onClick={handleSubmit}
              className="getProfiledBtn poppins font-semibold text-sm leading-5"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCenterFrom;
