import Polaroid from "../../../Helpers/Polaroid";
import "./style.css";

function ReelBanner() {
  return (
    //Reel Banner HTML
    <div className="bg-blue-light py-8 inter w-full mx-auto">
      <div class="w-full pt-32 text-center md:hidden">
        <Polaroid src="/assets/gaurav_ig.png" />
      </div>
      <div class="pt-6 pb-10 md:pb-0">
        <div class="container px-4 lg:px-20  mx-auto md:my-8 md:mb-16 flex flex-wrap flex-col md:flex-row items-center">
          <div class="flex flex-col w-full md:w-1/2 justify-center items-start text-center md:text-left">
            <h5 class="my-4 md:mt-32 w-full text-md md:text-lg font-light leading-6 text-primary-peddle-500 tracking-[1.2px] lg:tracking-[1.4px] poppins">
              FEATURED HIGHLIGHT
            </h5>
            <h1 class="my-0 text-[32px] w-full font-medium tracking-[3.2px] leading-9 text-black poppins">
              <span className="text-primary-caarya-medium">Adarsh Gourav</span>{" "}
              in the House
            </h1>
            <p class="mt-8 leading-10 font-light tracking-[1.6px] text-lg mb-8 text-black inter">
              Guess who showed up at the latest Caarya town hall ! <br />
              Adarsh Gourav, of Netflix's 'The White Tiger' fame and BAFTA
              nominee takes a break from the busy BAFTA weekend to discuss
              college, humble beginnings and more with the Caarya students.
            </p>
          </div>
          <div class="my-4 md:mt-32 w-full md:w-1/2 text-center hidden md:block">
            <Polaroid src="/assets/gaurav_ig.png" />
            {/* <Polaroid src="/assets/gaurav_ig.png" />
            <Polaroid src="/assets/gaurav_ig.png" />
            <Polaroid src="/assets/gaurav_ig.png" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReelBanner;
