import { ScrollReveal } from "Components/Common/Helpers/ScrollReveal";
import { useScroll, motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "60%" },
};

function SubSection({ subtitle, heading, subtext, cardDetails }) {
  const [scrollOffset, setScrollOffset] = useState(-150);
  const [ref2, inView2] = useInView();
  const control_h = useAnimation();
  useEffect(() => {
    console.log("inView", inView2);
    if (inView2) {
      control_h.start("visible");
    }
  }, [inView2]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-300);
    } else {
      setScrollOffset(-500);
    }
  }, []);
  return (
    <>
      <div class="py-10 lg:py-14 relative">
        <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="w-full flex flex-col items-center justify-center space-y-2 lg:space-y-4">
            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="hidden"
                animate={control_h}
                className="lg:mt-6 px-4 py-4 w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-xs font-light md:text-sm uppercase px-6 leading-normal text-primary-peddle-500 tracking-[1.2px] lg:tracking-[1.4px] text-center poppins pb-2 border-b-2 lg:border-b-4 w-max border-primary-caarya-medium">
                  {subtitle}
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-2xl font-light md:text-[32px] px-6 leading-9 tracking-[2.4px] lg:tracking-[3.2px] lg:leading-[48px] text-black text-center poppins w-max">
                  {heading}
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-sm font-light md:text-base leading-normal text-primary-neutral-500 text-center inter max-w-4xl capitalize">
                  {subtext}
                </h4>
              </motion.div>
            </ScrollReveal>
          </div>
          <div className="max-w-7xl mx-auto mt-4 flex flex-col w-full relative">
            <div className="w-full flex flex-col space-y-14 z-20 mt-6 lg:mt-10">
              <Card {...cardDetails} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubSection;

function Card({ reverse, svg, title, description, image }) {
  return (
    <div
      className={`w-full flex flex-col items-center space-y-4 lg:space-y-0 lg:flex-row lg:space-x-12 justify-between`}
    >
      {(!reverse || window.innerWidth < 1024) && (
        <img src={image} alt="" className="object-cover" />
      )}
      <div className="flex flex-col items-start space-y-5 p-4 w-full lg:w-7/12">
        <div className="w-8 h-8 lg:w-[42px] lg:h-[42px]">{svg}</div>
        {reverse ? (
          <div
            style={{
              background: "linear-gradient(90deg, #C6F8F9 0%, #FCFCFC 100%)",
            }}
            className="w-20 lg:w-[120px] h-2"
          />
        ) : (
          <div
            style={{
              background: "linear-gradient(90deg, #FEB8B1 0%, #FCFCFC 100%)",
            }}
            className="w-20 lg:w-[120px] h-2"
          />
        )}

        <h1 className="inter text-black text-lg lg:text-xl">{title}</h1>
        <p className="text-primary-neutral-800 inter text-base lg:text-lg font-light">
          {description}
        </p>
      </div>
      {reverse && window.innerWidth >= 1024 && (
        <img src={image} alt="" className="object-cover" />
      )}
    </div>
  );
}
