import FHomeBannerNav from "Components/Common/FHomeBannerNav";
import Footer from "Components/Common/Footer";
import AboutCover from "Components/FutureVersion/AboutusComponents/AboutCover";
import AboutFocusPoints from "Components/FutureVersion/AboutusComponents/AboutFocusPoints";
import AboutLeadershipNote from "Components/FutureVersion/AboutusComponents/AboutLeadershipNote";
import AboutTCM from "Components/FutureVersion/AboutusComponents/AboutTCM";
import AboutCGC from "Components/FutureVersion/AboutusComponents/AboutCGC";
import AboutVerticals from "Components/FutureVersion/AboutusComponents/AboutVerticals";
import AboutUsStats from "Components/FutureVersion/AboutusComponents/HomeStats";
import ReelFollowUs from "Components/FutureVersion/ReelComponents/ReelFollowUs";
import { useEffect } from "react";
import Loader from "Components/Common/Loader";
import { useRouteMatch } from "react-router-dom";
import { initializeReactGA } from "App";

const AboutusPage = () => {
  const route = useRouteMatch();
  useEffect(() => {
    initializeReactGA("About");
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      {!localStorage.getItem(route?.path) && <Loader />}
      <FHomeBannerNav />
      <AboutCover />
      <AboutFocusPoints />
      <AboutUsStats />
      <AboutVerticals />
      <AboutCGC />
      <AboutLeadershipNote />
      <AboutTCM />
      <Footer />
    </div>
  );
};

export default AboutusPage;
