import AboutusPage from "Pages/AboutUsPage";
import FBlog from "Pages/FutureVersion/Blog";
import FHighlightReel from "Pages/FutureVersion/HighlightReel";
import FHelpCenter from "Pages/HelpCenter";
import StartupPage from "Pages/StartupPage";
import FStudents from "Pages/StudentsPage";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTop from "./Components/Common/ScrollToTop";
import FCaaryaverse from "./Pages/Caaryaverse";
import FutureHome from "./Pages/FutureHome";
import ReactGA from "react-ga4";
import { TRACKING_ID } from "config";
ReactGA.initialize(TRACKING_ID); //Replace Google Analytics code here!

function initializeReactGA(title) {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: title,
  });
}

function storeEvent({ category, action, label }) {
  // Send a custom event
  ReactGA.event({
    category: category,
    action: action,
    label: label, // optional
    // value: 99, // optional, must be a number
    // nonInteraction: true, // optional, true/false
    // transport: "xhr", // optional, beacon/xhr/image
  });
}

function App() {
  initializeReactGA("Landed");
  return (
    <Router>
      <div>
        <div className="website-bg">
          <ScrollToTop />
          <Switch>
            <Route path="/explorecaarya">
              <FCaaryaverse />
            </Route>
            <Route path="/studentPage">
              <FStudents />
            </Route>
            <Route path="/startup">
              <StartupPage />
            </Route>
            <Route path="/highlightreel">
              <FHighlightReel />
            </Route>
            <Route path="/thecuriouspro">
              <FBlog />
            </Route>
            <Route path="/aboutcaarya">
              <AboutusPage />
            </Route>
            <Route path="/joincaarya">
              <FHelpCenter />
            </Route>
            <Route path="/">
              <FutureHome />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
export { storeEvent, initializeReactGA };
