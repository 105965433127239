import { ArrowRightIcon } from "@heroicons/react/solid";
import { storeEvent } from "App";
import { Link } from "react-router-dom";

function CuriousProLatest() {
  return (
    <div className="bg-blue-light p-6 sm:p-16 mb-16">
      <div class="container px-6  mx-auto">
        <div class="items-center md:flex">
          <div class="w-full md:w-1/2 hidden md:block">
            <img
              class="w-full h-80 max-w-2xl mt-20 mb-20 rounded-xl object-cover"
              src="/assets/tcpchapterone.png"
              alt=""
            />
          </div>
          <div class="max-w-lg flex flex-col justify-center ml-0 md:ml-10 w-full md:w-1/2 mt-10 md:mt-0  ">
            <h4 class="poppins text-sm font-medium text-primary-peddle-500 uppercase  md:text-base mt-12 lg:mt-0">
              THE CURIOUS PRO | Chapter 1
            </h4>
            <h1 class="poppins mt-4 text-2xl font-medium text-primary-caarya-medium  md:text-[32px]">
              Striking a Chord
            </h1>
            <div class="w-full md:w-1/2 highlight-img mt-10 sm:hidden">
              <img
                class="w-full max-w-2xl h-40 rounded-xl object-cover"
                src="/assets/tcpchapterone.png"
                alt=""
              />
            </div>

            <p class="inter mt-2 text-black font-light mt-8">
              I was nothing but the king of chaos when it came to organization
              or work, the clock would always make me cry and often chase me
              into nightmares as procrastination was my second nature. Back
              then, you would often find me in my room full of scattered books
              as I would run around to search for my phone ringing to Ed
              Sheeran's 'Shape of You'.
            </p>
            <Link
              to="/thecuriouspro"
              onClick={() => {
                storeEvent({
                  category: "Media",
                  action: "redirect",
                  label: "Curious Pro",
                });
              }}
              className="self-center mt-8 md:self-start"
            >
              <button className="getProfiledBtn poppins font-semibold text-sm leading-5">
                Read Full Story
                <ArrowRightIcon className="w-4 h-4 -rotate-45 transform ml-2.5" />
              </button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CuriousProLatest;
