function HelpCenterCover({ onButtonClick }) {
  return (
    //Home Banner HTML
    <div className="bg-red-light py-8 inter w-full mx-auto container-b-rounded">
      <div class="w-full pt-10 text-center md:hidden">
        <img class="w-full mt-20" src="/assets/HelpCenter/cover.png" />
      </div>
      <div class="pt-6 pb-10 md:pb-0">
        <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div class="flex flex-col w-full md:w-1/2 justify-center items-center md:items-start text-center md:text-left">
            <h1 class="my-4 mt-0 md:mt-32 text-3xl md:text-4xl font-bold leading-tight aqua-400 poppins">
              Help Center
            </h1>
            <p class="leading-normal text-xl md:text-xl mb-8 text-gray-800 inter md:w-11/12">
              Whether you're curious about features, have any feedbacks or want
              to connect with our coordinators - we're here to answer any and
              all questions.
            </p>
            <button
              onClick={onButtonClick}
              className="getProfiledBtn poppins font-semibold text-sm leading-5"
            >
              Contact Us &nbsp;&nbsp; >
            </button>
          </div>

          <div class="w-full md:w-1/2 md:py-24  text-center hidden md:block">
            <img class=" mx-auto" src="/assets/HelpCenter/cover.png" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpCenterCover;
