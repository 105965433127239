import VerseAnimation from "../../../Helpers/VerseAnimation";
import { ScrollReveal } from "Components/Common/Helpers/ScrollReveal";
import { useScroll, motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "60%" },
};
function AboutVerticals() {
  const [scrollOffset, setScrollOffset] = useState(-150);
  const [ref2, inView2] = useInView();
  const control_h = useAnimation();
  useEffect(() => {
    console.log("inView", inView2);
    if (inView2) {
      control_h.start("visible");
    }
  }, [inView2]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-300);
    } else {
      setScrollOffset(-500);
    }
  }, []);
  return (
    //Caaryaverse Cover
    <div class="">
      <ScrollReveal offset={scrollOffset}>
        <motion.div
          ref={ref2}
          variants={cardsVariant}
          initial="hidden"
          animate={control_h}
        >
          {" "}
          <h1 class=" mt-16 poppins w-full text-xl lg:text-[32px]  font-medium leading-[48px] text-center">
            Our Unique Approach
          </h1>
        </motion.div>
      </ScrollReveal>{" "}
      {/* <div class="w-full mb-4 mt-4">
				<div class="h-1 mx-auto red-gradient w-1/6 my-0 py-0 rounded-t"></div>
			</div> */}
      <div class="my-6">
        <VerseAnimation />
      </div>
      <div class="flex flex-wrap flex-col content-center text-center pb-10 md:pb-0">
        <div class="w-full mx-auto max-w-2xl">
          <div class="text-primary-neutral-800 inter text-justify tracking-[0.45px] leading-8 text-[18px] mx-6 mb-8">
            Caarya consists of several verticals comprising of programs, events
            and challenges designed to give you a headstart in the 2025 Gig
            economy and Creator Economy landscape. Each vertical serves a
            specific purpose in the students growth and helps the student learn,
            train, work, network, ideate, accelerate and grow
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutVerticals;
