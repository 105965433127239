import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { ScrollReveal } from "Components/Common/Helpers/ScrollReveal";
import { useScroll, motion, useAnimation } from "framer-motion";

const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "60%" },
};

function StartupReady() {
  const [scrollOffset, setScrollOffset] = useState(-150);
  const [ref2, inView2] = useInView();
  const control_h = useAnimation();
  useEffect(() => {
    console.log("inView", inView2);
    if (inView2) {
      control_h.start("visible");
    }
  }, [inView2]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-300);
    } else {
      setScrollOffset(-500);
    }
  }, []);
  return (
    <>
      <div class="py-10 lg:py-14 relative">
        <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="w-full flex flex-col items-center justify-center space-y-2 lg:space-y-4">
            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="hidden"
                animate={control_h}
                className="mt-6 py-4 w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-xs font-light md:text-sm uppercase px-6 leading-normal text-primary-peddle-500 tracking-[1.2px] lg:tracking-[1.4px] text-center poppins pb-2 border-b-2 lg:border-b-4 w-max border-primary-caarya-medium">
                  The Future Talent Pool
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-2xl font-light md:text-[32px] px-6 leading-9 tracking-[2.4px] lg:tracking-[3.2px] lg:leading-[48px] text-black text-center poppins w-max">
                  Meet Our Startup-Ready Workforce
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-sm font-light md:text-base leading-normal text-primary-neutral-500 text-center inter max-w-4xl capitalize">
                  Versatile individuals proficient across multiple domains and
                  roles
                </h4>
              </motion.div>
            </ScrollReveal>
          </div>
          <div className="w-full relative py-14 grid grid-cols-2 lg:grid-cols-4 gap-x-5 lg:gap-x-16 gap-y-16 lg:gap-y-20">
            {[
              {
                name: "Market Researchers",
                description: "To understand your target market segmentation",
                image: "/assets/FutureVersion/startup/11.svg",
              },
              {
                name: "Product Strategists",
                description:
                  "To increase the value of your products to your customers",
                image: "/assets/FutureVersion/startup/22.svg",
              },
              {
                name: "Content Creators",
                description:
                  "To increase your digital footprint and brand value",
                image: "/assets/FutureVersion/startup/33.svg",
              },
              {
                name: "Brand Strategists",
                description:
                  "To help create a unique brand story and set you apart",
                image: "/assets/FutureVersion/startup/44.svg",
              },
              {
                name: "UX Designers",
                description:
                  "To increase product engagement by creating amazing experiences",
                image: "/assets/FutureVersion/startup/55.svg",
              },
              {
                name: "Visual Designers",
                description: "To finalize on your branding and visual appeal",
                image: "/assets/FutureVersion/startup/66.svg",
              },
              {
                name: "User Interface Designers",
                description: "To design amazing screens and prototypes",
                image: "/assets/FutureVersion/startup/77.svg",
              },
              {
                name: "Developers",
                description: "To bring your designs to life",
                image: "/assets/FutureVersion/startup/88.svg",
              },
            ]?.map((item) => {
              return <Card item={item} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default StartupReady;

function Card({ item }) {
  const [ref, inView] = useInView();
  const { scrollYProgress } = useScroll();
  const control_1 = useAnimation();
  const control_2 = useAnimation();
  useEffect(() => {
    if (inView) {
      control_1.start("visible");
      setTimeout(() => {
        control_2.start("visible");
      }, 200);
    }
  }, [inView, scrollYProgress]);

  return (
    <motion.div
      ref={ref}
      variants={cardsVariant}
      initial="hidden"
      animate={control_1}
      className="flex flex-col items-center justify-start space-y-5"
    >
      <img
        src={item?.image}
        alt=""
        className="lg:w-20 h-16 w-16 lg:h-20 object-contain"
      />
      <div className="flex flex-col items-center justify-center space-y-2 w-full">
        <h1 class="text-sm lg:text-base font-semibold lg:leading-6 text-primary-neutral-800 inter lg:tracking-[0.8px] text-center">
          {item?.name}
        </h1>
        <p class="text-sm inter text-primary-neutral-800 leading-5 text-center font-light">
          {item?.description}
        </p>
      </div>
    </motion.div>
  );
}
