import { ScrollReveal } from "Components/Common/Helpers/ScrollReveal";
import { useScroll, motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "60%" },
};

function Ikigai() {
  const [scrollOffset, setScrollOffset] = useState(-150);
  const [ref2, inView2] = useInView();
  const control_h = useAnimation();
  useEffect(() => {
    console.log("inView", inView2);
    if (inView2) {
      control_h.start("visible");
    }
  }, [inView2]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-300);
    } else {
      setScrollOffset(-500);
    }
  }, []);
  return (
    <>
      <div class="py-14 relative">
        <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="w-full flex flex-col items-center justify-center space-y-2 lg:space-y-4">
            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="hidden"
                animate={control_h}
                className="mt-6 py-4 w-full max-w-[100vw] z-20 flex flex-row items-center justify-center px-4"
              >
                <h4 class="text-xs font-light md:text-sm uppercase px-6 leading-normal text-primary-peddle-500 tracking-[1.2px] lg:tracking-[1.4px] text-center poppins pb-2 border-b-2 lg:border-b-4 w-max border-primary-caarya-medium">
                  Experiential and Purpose-Driven Growth{" "}
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-2xl font-light md:text-[32px] px-6 leading-9 tracking-[2.4px] lg:tracking-[3.2px] lg:leading-[48px] text-black text-center poppins w-max">
                  Choice-Driven Work That Aligns to Your Ikigai{" "}
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-sm font-light md:text-base leading-normal text-primary-neutral-500 text-center inter max-w-4xl capitalize">
                  Measure your growth through gamified work experiences that
                  Shape your career path{" "}
                </h4>
              </motion.div>
            </ScrollReveal>
          </div>
          <div className="flex flex-col items-center space-y-10 lg:space-y-6 max-w-7xl mx-auto mt-10">
            <img
              src={
                window.innerWidth < 1024
                  ? "/assets/FutureVersion/students/mobileIkigai.png"
                  : "/assets/FutureVersion/students/laptopIkigai.png"
              }
              alt=""
              className="lg:scale-100"
            />

            <img
              src={"/assets/FutureVersion/students/mobileQuest.png"}
              alt=""
              className="block lg:hidden"
            />

            {/* <div className="grid lg:grid-cols-3 gap-10">
              <img
                src="/assets/FutureVersion/students/graph1.png"
                alt=""
                className=""
              />
              <div className="flex flex-col items-center space-y-4 justify-center">
                <h1 className="text-primary-neutral-800 font-satoshi text-sm font-bold ">
                  Quest Completed
                </h1>
                <div className="flex flex-row items-center justify-center text-primary-neutral-800 font-satoshi text-[10px] font-bold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M8.26495 14.1963H7.59829L8.26495 9.52962H5.93162C5.34495 9.52962 5.71162 9.02962 5.72495 9.00962C6.58495 7.48962 7.87829 5.22296 9.60496 2.19629H10.2716L9.60496 6.86296H11.945C12.2116 6.86296 12.3583 6.98962 12.2116 7.30296C9.57829 11.8963 8.26495 14.1963 8.26495 14.1963Z"
                      fill="url(#paint0_linear_1829_27099)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1829_27099"
                        x1="8.93465"
                        y1="2.19629"
                        x2="8.93465"
                        y2="14.1963"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#EF8FCF" />
                        <stop offset="1" stop-color="#8F1DC9" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <p>+400XP</p>
                </div>
              </div>
              <img
                src="/assets/FutureVersion/students/graph2.png"
                alt=""
                className=""
              />
            </div>
            <div className="flex flex-row items-center justify-center">
              <div
                style={{
                  boxShadow:
                    "0px 2.771px 4.157px -0.693px rgba(0, 0, 0, 0.10), 0px 1.386px 2.771px -1.386px rgba(0, 0, 0, 0.10)",
                }}
                className="rounded-2xl max-w-[510px] mx-auto py-4 px-5 bg-white  border-primary-neutral-300 border-[0.6px]"
              >
                <div className="flex flex-col items-center lg:flex-row justify-between w-full">
                  <div className="flex flex-col items-center lg:items-start space-y-1">
                    <h1 className="text-primary-neutral-800 font-manrope text-[13px] font-medium leading-5 tracking-[0.2px]">
                      Explorative Study of Game Environments
                    </h1>
                    <p className="text-primary-neutral-500 font-satoshi text-[8px] font-bold leading-3 tracking-[0.2px]">
                      Environmental Designer
                    </p>
                  </div>
                  <img
                    src="/assets/FutureVersion/students/demo.png"
                    alt=""
                    className="h-14 w-14 mt-2.5 lg:mt-0"
                  />
                </div>
                <p className="text-primary-neutral-800 font-satoshi text-[11px] mt-4 leading-4 tracking-[0.2px]">
                  In this activity, you will combine your technical knowledge of
                  game environment design with your information gathering skills
                  to carry out a comprehensive study on how various games create
                  rewarding exploration for players. Choose several games known
                  for their exceptional environmental design, considering a
                  diverse range of mainstream and indie titles to explore unique
                  approaches to environmental design, rewards, and storytelling.
                </p>
                <div className="py-4 flex border-b border-[#D9D9D9] flex-row items-stretch space-x-2.5">
                  <div className="flex flex-row items-center space-x-2.5 rounded border-[0.6px] border-[#FEB8B1]">
                    <p className="py-1.5 px-2.5 text-primary-neutral-800 font-satoshi font-bold text-[8px] leading-3 tracking-[0.2px]">
                      Research & Analysis{" "}
                    </p>
                  </div>
                  <div className="flex flex-row items-center space-x-2.5 rounded border-[0.6px] border-[#93CEEC]">
                    <p className="py-1.5 px-2.5 text-primary-neutral-800 font-satoshi font-bold text-[8px] leading-3 tracking-[0.2px]">
                      Game Design
                    </p>
                  </div>
                </div>
                <div className="pt-4 flex flex-row items-center justify-between">
                  <div className="flex flex-row space-x-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M8.20172 15.2088H7.50886L8.20172 10.3588H5.7767C5.16698 10.3588 5.54805 9.83915 5.56191 9.81837C6.4557 8.23864 7.79986 5.8829 9.59437 2.7373H10.2872L9.59437 7.58735H12.0263C12.3035 7.58735 12.4559 7.71899 12.3035 8.04464C9.56666 12.8185 8.20172 15.2088 8.20172 15.2088Z"
                        fill="url(#paint0_linear_1852_91)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1852_91"
                          x1="8.89773"
                          y1="2.7373"
                          x2="8.89773"
                          y2="15.2088"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#EF8FCF" />
                          <stop offset="1" stop-color="#8F1DC9" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <div className="flex flex-col">
                      <p className="text-primary-neutral-800 font-satoshi text-[9px] font-bold leading-4 tracking-[0.2px]">
                        400
                      </p>
                      <p className="text-primary-neutral-800 font-satoshi text-[8px] leading-3 tracking-[0.2px]">
                        Maximum Possible XP
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row space-x-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                    >
                      <path
                        d="M8.55256 2.04395C4.72796 2.04395 1.63086 5.14797 1.63086 8.97258C1.63086 12.7972 4.72796 15.9012 8.55256 15.9012C12.3841 15.9012 15.4881 12.7972 15.4881 8.97258C15.4881 5.14797 12.3841 2.04395 8.55256 2.04395ZM8.55949 14.5155C5.49704 14.5155 3.01659 12.035 3.01659 8.97258C3.01659 5.91012 5.49704 3.42967 8.55949 3.42967C11.6219 3.42967 14.1024 5.91012 14.1024 8.97258C14.1024 12.035 11.6219 14.5155 8.55949 14.5155ZM8.90592 5.50826H7.86663V9.66544L11.5042 11.848L12.0238 10.9957L8.90592 9.14579V5.50826Z"
                        fill="#1A6BE5"
                      />
                    </svg>
                    <div className="flex flex-col">
                      <p className="text-primary-neutral-800 font-satoshi text-[9px] font-bold leading-4 tracking-[0.2px]">
                        3 Days
                      </p>
                      <p className="text-primary-neutral-800 font-satoshi text-[8px] leading-3 tracking-[0.2px]">
                        Days to Complete
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <p className="text-primary-neutral-500 inter text-sm lg:text-base font-light max-w-[800px] mt-6 mx-auto leading-6 lg:leading-8 tracking-[0.7px] text-center lg:tracking-[0.8px]">
              Engage in tasks that sharpen both technical and transferable
              skills, visualized through personalized Skill Maps and Skill
              Radars. This approach fosters holistic career growth, guided by
              your unique purpose, with mentorship from experts to
              enhance your journey.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ikigai;
