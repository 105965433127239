import "./style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
const defaultTestimonials = [
  {
    name: "Jaskaran Bhatia",
    image: "/faces/005_jaskaran.png",
    college: "BITS Goa",
    position: "Graphic Designer",
    testimonial:
      "Each stage is worth cherishing and it has been altogether a new experience for me.I learnt how to manage time efficiently and work with developers from around the globe.",
  },
  {
    name: "Aksa Memon",
    image: "/faces/039_aksa.png",
    college: "VIT, Pune",
    position: "Product Manager",
    testimonial:
      "The work culture is extremely professional and nonetheless it feels like a family.I get to do hands - on live projects which is enhancing my exposure and experience.",
  },
  {
    name: "Akshay Goli",
    image: "/faces/011_akshaygoli.png",
    college: "IIIT, Bengaluru",
    position: "Fullstack Developer",
    testimonial:
      "The learning at caarya helped me grow my own source organization ‘Canvasbird’. Caarya is the place to be if you are passionate about building products and need mentorship from smart and experienced people.",
  },
];
function Testimonials() {
  const settings = {
    className: "center mx-4 ",
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    infinite: true,
  };
  return (
    //Home Banner HTML
    <div className="inter w-full mx-auto">
      <div class="pt-4 pb-10">
        <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center w-full">
          <div class="flex flex-col text-center w-full justify-center items-center">
            <h1 class="my-4 text-center md:text-left mt-0 md:mt-32 text-3xl md:text-4xl font-bold leading-tight text-gray-800 poppins">
              Testimonials
            </h1>
          </div>
          <div className="w-full">
            <Slider
              {...settings}
              prevArrow={
                <div className="focus:outline-none">
                  <div className=" bg-gray-850 rounded-full h-5 w-5 flex flex-row items-center justify-center">
                    <ChevronLeftIcon className="text-white h-4 w-4" />
                  </div>
                </div>
              }
              nextArrow={
                <div className="focus:outline-none">
                  <div className=" bg-gray-850 rounded-full h-5 w-5 flex flex-row items-center justify-center">
                    <ChevronRightIcon className="text-white h-4 w-4" />
                  </div>
                </div>
              }
            >
              {defaultTestimonials?.map((e) => {
                return (
                  <div className="xl:w-1/3 lg:w-5/12 w-full  p-2 sm:w-3/5 md:w-5/12 xl:pb-0 xl:px-12 pb-12 my-6">
                    <div className="bg-gray-100 w-full rounded-lg h-40" />
                    <div className="flex flex-col items-start p-2.5">
                      <h1 class="my-3 text-center md:text-left font-bold leading-tight text-gray-800 poppins">
                        {e?.name}
                      </h1>
                      <p class="leading-normal text-xs mb-4 text-gray-800 inter">
                        {e?.college}
                      </p>
                      <p class="leading-normal text-sm line-clamp-5 text-gray-800 inter">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it
                      </p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
