import { initializeReactGA } from "App";
import FHomeBannerNav from "Components/Common/FHomeBannerNav";
import Footer from "Components/Common/Footer";
import Loader from "Components/Common/Loader";
import About from "Components/FutureHomeComponents/About";
import AcrossNetwork from "Components/FutureHomeComponents/AcrossNetwork";
import HomeBanner from "Components/FutureHomeComponents/Banner";
import FHomeStats from "Components/FutureHomeComponents/FHomeStats";
import ForStartup from "Components/FutureHomeComponents/ForStartup";
import ForStudents from "Components/FutureHomeComponents/ForStudents";
import HelpCenter from "Components/FutureHomeComponents/HelpCenter";
import Indulgence from "Components/FutureHomeComponents/Indulgence";
import StudentStartup from "Components/FutureHomeComponents/StudentStartup";
import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

function FutureHome() {
  const route = useRouteMatch();
  useEffect(() => {
    initializeReactGA("Home");
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div class="bg-white">
      {!localStorage.getItem(route?.path) && <Loader />}
      <FHomeBannerNav />
      <HomeBanner />
      {/* <CaaryaStartup /> */}
      {/* <StudentStartup /> */}
      <ForStudents />
      <ForStartup />
      <Indulgence />
      <About />
      {/* <FHomeStats />
      <AcrossNetwork />
      <HelpCenter /> */}
      <Footer />
    </div>
  );
}

export default FutureHome;
