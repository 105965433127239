import FHomeBannerNav from "Components/Common/FHomeBannerNav";
import Footer from "Components/Common/Footer";
import BlogBanner from "Components/FutureVersion/BlogComponents/BlogBanner";
import BlogContest from "Components/FutureVersion/BlogComponents/BlogContest";

import "./style.css";
import { useEffect } from "react";
import { initializeReactGA } from "App";

function FBlog() {
  useEffect(() => {
    initializeReactGA("Blog");
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <FHomeBannerNav />
      <BlogBanner />

      <div class="text-center pt-16 md:pt-16 inter">
        <p class="text-sm md:text-base text-green-500 font-bold">
          15 APRIL 2021 <span class="text-gray-900">/</span> THE CURIOUS
          PROFESSIONAL
        </p>
        <h1 class="font-bold break-normal text-3xl poppins">
          Striking a chord
        </h1>
      </div>

      <div class="container w-full max-w-6xl mx-auto bg-cover mt-8 rounded">
        <img
          class="w-full max-w-6l h-80 object-none"
          src="/assets/tcpchapterone.png"
          alt="Cover Photo"
        />
      </div>

      <div class="container max-w-5xl mx-auto">
        <div class="mx-0 sm:mx-6">
          <div className="w-full p-8 md:p-24 text-md text-gray-800 leading-normal text-justify inter">
            {/* <p class="text-2xl md:text-3xl mb-5">
					👋 Welcome fellow <a class="text-gray-800 hover:text-green-500 no-underline border-b-2 border-green-500" href="https://www.tailwindcss.com">Tailwind CSS</a> and <a class="text-gray-800 hover:text-green-500 no-underline border-b-2 border-green-500" href="https://www.ghost.org">Ghost</a> fan.  This starter template is an attempt to replicate the default Ghost theme <a class="text-gray-800 hover:text-green-500 no-underline border-b-2 border-green-500" href="https://demo.ghost.io/welcome">"Casper"</a> using Tailwind CSS and vanilla Javascript.
				</p> */}

            <p class="py-6">
              I am sitting in my rooftop garden turned workstation while having
              my Sunday brunch. Oh, I didn't introduce myself yet - Hello, I'm
              Aarav, a mentor to a developer at a campus initiative and
              community called DevSoc and an expert in mobile and web
              development.
            </p>

            <p class="py-6">
              Due to 2020, I was pushed back to my hometown from my campus in
              Goa. Although my Gary Vee mobile cover inspires me to work
              diligently, Sundays are usually remittances of trips to Ooty and
              Munnar with my friends, and about reading my favorite books
              ranging from Shashi Tharoor to Minimalism. So much has progressed
              and worked well for me in these two years , a complete turn over
              of my priorities.
            </p>

            <ol>
              <li class="py-3">
                I was nothing but the king of chaos when it came to organization
                or work, the clock would always make me cry and often chase me
                into nightmares as procrastination was my second nature. Back
                then, you would often find me in my room full of scattered books
                as I'd run around to search for my mobile buzzing to Ed
                Sheeran's 'Shape of You'. But I'm sure Ed Sheeran too had his
                share of distracted days. His smiling face on the poster in my
                room never really helped my anxieties but he did inspire me to
                pick up the acoustic guitar just like Kishore Kumar's
                oldie-goldies.{" "}
              </li>

              <li class="py-3">
                I would take a shower, freshen up and rush through the corridors
                after reaching my campus to meet Nishant, the college
                super-hero, and creator of DevSoc who in reality was chill about
                time and work, he never expressed his disappointment about me
                being late to his meetings, although I could understand that it
                did. But this wasn't the case with my friend Sahej, a night owl
                who could get done a lot of homework, extremely organized and
                well dressed but confused about the future just like me except
                he did not have any other distractions like guitar, reading,
                music and well laziness and his romance could be summed up in
                just three words - Coding, Coding, and Coding, something that
                Nishant always admired about him. However, if it were not for
                DevSoc, Nishant, or Sahej and of course my music icons, 2020
                would have been a disaster. Who knew the prophecy was coming so
                close to the truth when it was about me becoming not just a
                student but a working student who dreams about long drives,
                getting his own house, his room full of books, and his guitar
                collection.{" "}
              </li>

              <li class="py-3">
                By the winter of 2019, I was picking up my skills fast and
                unknowingly discovered that I was thriving under pressure and
                finding a purpose in my campus life besides attending classes.
                But then 2020 happened, and I was happy about returning home but
                with varied assumptions like, "This Is It", "I'm done", no more
                campus life, or DevSoc. Panic struck me in my hostel room when I
                decided to call up Nishant and Sahej, Ed Sheeran was still
                smiling at me from the poster on the bedroom wall, although now
                I didn't pick up the guitar to soothe myself, even Kishore Kumar
                couldn't help.{" "}
              </li>

              <li class="py-3">
                It was on this day that Nishant gave me the responsibility of
                training and mentoring new initiates at DevSoc and the Mobile
                application Club, and The Department of Journalism kept calling
                me with new sets of projects for March and April. The country
                was entering total lockdown and here I was, taking on new
                responsibilities as a second-year student. Later that day, there
                was a knock on the door and to my astonishment, it was pizza
                delivery, a gift from DevSoc for my months of hard work, and a
                letter attached to the package, which said, "Congratulations!"
                that's all.{" "}
              </li>

              <li class="py-3">
                I took a moment to make sense of my role in this situation and
                understood that if I do not work on my ambitions and be more
                focused on self-development at this stage, my life would
                probably take a back seat if I let the reins loose. A few days
                later, someone's call changed my outlook entirely and gave me
                immense hope and encouragement I have heard of him in rumors
                about him being a legend, no one ever saw him, those who did or
                came in contact with him always spoke of him as if speaking of
                someone who works from the shadows. My first hello expected a
                deep bass voice of "Good morning, am I speaking to Aarav?" but I
                was pretty much taken aback by "Hey is this Aarav?" and "Bro I
                got your number from Nishant". It was like an ice breaker and
                apparently, Nishant had suggested my name to him for some
                guidance regarding a personal organization. I did not know how
                ten minutes passed in conversing with him.{" "}
              </li>

              <li class="py-3">
                Here I was in a room filled with unwashed clothes, scattered
                books, and hopelessness about the future, and on the other end
                was Ravi, introducing me to a super powerful tool of Notion
                which can be used for both "personal" and "professional"
                organization. After that, I got a "smiley" from Nishant on my
                Whatsapp. I know what it meant. Yes, Ravi is still a myth to
                many of us in the startup ecosystem but he shows up in the
                scariest moments, like a guiding voice for complex problems
                which others can't solve.{" "}
              </li>

              <li class="py-3">
                I won't get into what happened in 2020, I became what I am
                today, and there's a long way to go. My DevSoc colleague Ashwin
                got an internship at Microsoft, and he wants to meet up this
                weekend (with all social distancing maintained) after months of
                not talking. There's a ton of work after that but, I am not
                stressed one bit. It's more about enjoying the process- to build
                the future we desire.
              </li>
            </ol>

            <blockquote class="border-l-4 border-green-500 italic my-8 pl-8 md:pl-12">
              I took a moment to make sense of my role in this situation and
              understood that if I do not work on my ambitions and be more
              focused on self-development at this stage, my life would probably
              take a back seat if I let the reins loose.
            </blockquote>
          </div>

          <div class="flex w-full items-center font-sans px-8 md:px-24 ">
            <div>
              <img
                class="w-20 h-20 rounded-full mr-4"
                src="/assets/thecuriouspro.jpg"
                alt="Avatar of Author"
              />
            </div>

            <div class="flex-1">
              <p class="text-base font-bold text-base md:text-xl leading-none">
                The Curious Pro
              </p>
              <p class="text-gray-600 text-xs md:text-base">
                Musings of a Working Student Professional{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <BlogContest />

      <Footer />
    </div>
  );
}

export default FBlog;
