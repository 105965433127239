/* eslint-disable no-undef */
let ENDPOINT = "";
let CHRONOS_ENDPOINT = "";

switch (process.env.REACT_APP_ENV) {
  case "production":
    // Endpoint for Production website
    ENDPOINT = "https://caaryame-backend-tk4gwh76qa-el.a.run.app";
    CHRONOS_ENDPOINT = "https://chronos-prod-tk4gwh76qa-el.a.run.app";
    break;
  case "staging":
    // Endpoint for Staging website
    ENDPOINT = "https://caaryame-backend-tk4gwh76qa-el.a.run.app";
    break;
  case "development":
    // Endpoint for Development website
    ENDPOINT = "https://caaryame-backend-tk4gwh76qa-el.a.run.app";
    CHRONOS_ENDPOINT = "https://chronos-dev-tk4gwh76qa-el.a.run.app";
    break;
  case "local":
    // Endpoint for Local
    ENDPOINT = "http://localhost:3001";
    CHRONOS_ENDPOINT = "http://localhost:4193";
    break;
  default:
    // Default Endpoint
    ENDPOINT = "https://caaryame-backend-tk4gwh76qa-el.a.run.app";
    CHRONOS_ENDPOINT = "https://chronos-dev-tk4gwh76qa-el.a.run.app";
}
const TRACKING_ID = "G-62KSBWS5H3";
export { ENDPOINT, CHRONOS_ENDPOINT, TRACKING_ID };
