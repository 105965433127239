function AboutCover() {
  return (
    //Home Banner HTML
    <div className="bg-red-light py-8 inter w-full mx-auto">
      <div class="w-full flex flex-wrap justify-center flex-row items-center pt-32  text-center md:hidden">
        <img class="person1 h-48 sm:h-64 " src="assets/person1.png" alt="" />{" "}
      </div>
      <div class="pt-6 pb-10 md:pb-0">
        <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div class="flex flex-col w-full md:w-1/2 justify-center items-center md:items-start text-center md:text-left space-y-10">
            <h1 class="text-3xl md:text-4xl lg:tracking-[4.8px] font-medium text-primary-peddle-500 poppins">
              About <span className="text-primary-caarya-medium">Caarya</span>
            </h1>
            <p class="leading-[40px] text-lg md:text-lg text-black inter tracking-[1.6px]">
              Caarya is an ecosystem of Indian students, startups and promoters
              who aim to supplement knowledge, skills, value, beliefs, habits
              and attitudes with learning experiences.
            </p>
          </div>
          <div class="hidden md:flex flex-wrap flex-row w-96  ml-auto pt-10 sm:pt-0 mt-36 py-36">
            <img
              class="person1 h-48 sm:h-[440px] float-right"
              src="assets/person1.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutCover;
