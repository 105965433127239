import {
  AnimatePresence,
  useScroll,
  motion,
  useMotionValueEvent,
  useAnimation,
} from "framer-motion";
import React, { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { useInView } from "react-intersection-observer";

import Card from "./Card";
import { ScrollReveal } from "Components/Common/Helpers/ScrollReveal";

const list = [
  {
    index: "01",
    name: "Career Exploration",
    description:
      "This stage is for exploring a wide range of industries, roles, and skills to discover various career possibilities.",
    title: "Industry Safari",
    programDescription:
      "Explore various industries, roles, and skills to understand the broad spectrum of career opportunities.",
    textColor: {
      heading: "#C44EB9",
      description: "#451451",
    },
    bgGradient: "purple-program-gradient",
    image: "/assets/is1.svg",
    lineColor: "#C44EB9",
    recommend:
      "Recommended for freshmen (1st yr) who want to try out different career options.",
  },
  {
    index: "02",
    name: "Career Alignment",
    description:
      "This stage focuses on aligning students' skills and interests with potential career paths.",
    title: "Expert Reflections",
    programDescription:
      "Align your interests with potential career roles through expert guidance and tasks.",
    textColor: {
      heading: "#0497AE",
      description: "#002739",
    },
    bgGradient: "aqua-program-gradient",
    bgGradient2: "blue-program-gradient",
    textColor2: {
      heading: "#4F57B7",
      description: "#4F57B7",
    },
    image: "/assets/community1.svg",
    lineColor: "#0497AE",
    recommend:
      "Recommended for sophomores (2nd) who want to select the best career pathway.",
    title2: "Elements of Work",
    programDescription2:
      "Enhance career skills by learning about the dynamics of modern work environments.",
    image2: "/assets/work.svg",
    recommend2:
      "Recommended for sophomores (2nd) & juniors (3rd) who want to experience work in a professional environment.",
  },
  {
    index: "03",
    name: "Career Activation",
    description:
      "This stage is about actively starting a career path through specialized training and skill development.",
    title: "CASTLE Pathway",
    programDescription:
      "Initiate a specific career path through targeted technical tracks and role-based training.",
    textColor: {
      heading: "#4F57B7",
      description: "#4F57B7",
    },
    bgGradient: "blue-program-gradient",
    image: "/assets/castle.svg",
    lineColor: "#4F57B7",
    recommend:
      "Recommended for sophomores (2nd) who want to get started in career of choice.",
  },
  {
    index: "04",
    name: "Career Enhancement",
    description:
      "This stage is aimed at enhancing and refining skills and knowledge within a chosen career path.",
    title: "Professional Work",
    programDescription:
      "Enhance real-world skills through practical work experience in a team setting.",

    textColor: {
      heading: "#BA7507",
      description: "#532A01",
    },
    bgGradient: "yellow-program-gradient",
    image: "/assets/workYellow.svg",
    lineColor: "#BA7507",
    recommend:
      "Recommended for juniors (3rd) who want to increase their work experience as individual contributors ",
  },
  {
    index: "05",
    name: "Career Advancement",
    description:
      "This stage involves progressing to more advanced roles, often involving leadership or specialized expertise.",
    title: "Industrial Contracts",
    programDescription:
      "Advance in a career by transitioning from individual roles to team leadership through industrial contracts.",
    textColor: {
      heading: "#BA7507",
      description: "#532A01",
    },
    bgGradient: "yellow-program-gradient",
    image: "/assets/industry.svg",
    lineColor: "#ba7507",
    recommend:
      "Recommended for juniors (3rd) & seniors (4th)who want to work with startups and get pre placement offers.",
  },
];

const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "60%" },
};

const hexagonVariant = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 1 },
    translateX: 0,
    // rotate: 90,
  },
  hidden: {
    opacity: 0,
    scale: 1,
    translateX: "-100%",
    //  rotate: 90
  },
};

const hexagonVariant_1 = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 1 },
    translateX: 0,
    rotate: 90,
  },
  hidden: { opacity: 0, scale: 1, translateX: "-100%", rotate: 90 },
};

const hexagonVariant_2 = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "10%" },
};

function Programs() {
  const [ref, inView] = useInView({ triggerOnce: true });
  const [fadeOffset, setFadeOffset] = useState();
  const [lineColor, setLineColor] = useState("#c44eb9");
  const [fadeOffsetDiff, setFadeOffsetDiff] = useState(0);
  const [scrollOffset, setScrollOffset] = useState(-150);
  const [maxHeight, setMaxHeight] = useState();
  const { scrollYProgress, scrollY } = useScroll();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const control = useAnimation();
  const control_1 = useAnimation();
  const control_2 = useAnimation();
  const control_3 = useAnimation();
  const control_4 = useAnimation();
  const control_h = useAnimation();
  const control_h1 = useAnimation();

  const onScrollPage = () => {
    const offset = window?.scrollY;
    if (offset > 8 && !maxHeight) {
      var ele = document.getElementById("programsList");
      if (ele) {
        var offsetHeight = ele.offsetHeight;
        setMaxHeight(offsetHeight);
      }
    }
  };
  useEffect(() => {
    console.log("inView", inView2);
    if (inView2) {
      control_h.start("visible");
    }
  }, [inView2]);
  useEffect(() => {
    console.log("inView", inView3);
    if (inView3) {
      control_h1.start("visible");
    }
  }, [inView3]);
  useEffect(() => {
    window.addEventListener("scroll", onScrollPage, true);
    return () => {
      window.removeEventListener("scroll", onScrollPage);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1024) setFadeOffsetDiff((20 * 720) / 100);
    if (window.innerWidth < 768) setFadeOffsetDiff((60 * 720) / 100);
  }, []);

  useMotionValueEvent(scrollY, "change", (latest) => {
    setFadeOffset(latest);
  });

  useEffect(() => {
    if (inView) {
      control.start("visible");
      setTimeout(() => {
        control_1.start("visible");
      }, 200);
      setTimeout(() => {
        control_2.start("visible");
      }, 400);
      setTimeout(() => {
        control_3.start("visible");
      }, 600);
      setTimeout(() => {
        control_4.start("visible");
      }, 800);
    } else {
      control_4.start("hidden");
      setTimeout(() => {
        control_3.start("hidden");
      }, 200);
      setTimeout(() => {
        control_2.start("hidden");
      }, 400);
      setTimeout(() => {
        control_1.start("hidden");
      }, 600);
      setTimeout(() => {
        control.start("hidden");
      }, 800);
    }
  }, [inView, control, scrollYProgress]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-300);
    } else {
      setScrollOffset(-500);
    }
  }, []);
  return (
    <>
      <div class="py-10 relative">
        <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="w-full flex flex-col items-center justify-center space-y-2 lg:space-y-4">
            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="hidden"
                animate={control_h}
                className="mt-6 py-4 w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-xs font-light md:text-sm uppercase px-6 leading-normal text-primary-peddle-500 tracking-[1.2px] lg:tracking-[1.4px] text-center poppins pb-2 border-b-2 lg:border-b-4 w-max border-primary-caarya-medium">
                  Futureproof Learning Pathways
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-2xl font-light md:text-[32px] px-6 leading-9 tracking-[2.4px] lg:tracking-[3.2px] lg:leading-[48px] text-black text-center poppins w-max">
                  Career Guidance Through Developmental Stages
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-sm font-light md:text-base leading-normal text-primary-neutral-500 text-center inter max-w-4xl capitalize">
                  Create fluid career pathways with our tailored micro-learning
                  experiences for various student career stages.{" "}
                </h4>
              </motion.div>
            </ScrollReveal>
          </div>
          <div className="w-full min-h-screen relative mt-8">
            <ul
              id="programsList"
              className="relative max-w-6xl lg:mx-5 xl:mx-auto xl:px-0 py-10"
            >
              <motion.div
                style={{
                  height:
                    fadeOffset - fadeOffsetDiff > maxHeight
                      ? maxHeight
                      : fadeOffset - fadeOffsetDiff < 0
                      ? 10
                      : fadeOffset - fadeOffsetDiff,
                  backgroundColor: lineColor,
                }}
                className="w-1 rounded absolute top-0 left-[50%] bottom-0"
              />
              {list.length > 0 &&
                list.map((item, index) => {
                  return (
                    <ProgramCard
                      item={item}
                      setInViewItem={(val) => setLineColor(val)}
                    />
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <AnimatePresence>
        <div className="py-8 inter w-full mx-auto ">
          <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
            <div className="w-full flex flex-col items-center justify-center space-y-2 lg:space-y-4">
              <ScrollReveal offset={scrollOffset}>
                <motion.div
                  ref={ref3}
                  variants={cardsVariant}
                  initial="hidden"
                  animate={control_h1}
                  className="mt-6 py-4 w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
                >
                  <h4 class="text-xs font-light md:text-sm uppercase px-6 leading-normal text-primary-peddle-500 tracking-[1.2px] lg:tracking-[1.4px] text-center poppins pb-2 border-b-2 lg:border-b-4 w-max border-primary-caarya-medium">
                    Synergizing Tech and Empathy
                  </h4>
                </motion.div>
              </ScrollReveal>

              <ScrollReveal offset={scrollOffset}>
                <motion.div
                  ref={ref3}
                  variants={cardsVariant}
                  initial="visible"
                  animate={control_h1}
                  className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
                >
                  <h4 class="text-lg font-light md:text-[32px] px-6 leading-9 lg:leading-[48px] text-black text-center poppins w-max capitalize">
                    Enhance Your Human Potential{" "}
                  </h4>
                </motion.div>
              </ScrollReveal>

              <ScrollReveal offset={scrollOffset}>
                <motion.div
                  ref={ref3}
                  variants={cardsVariant}
                  initial="visible"
                  animate={control_h1}
                  className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
                >
                  <h4 class="text-sm font-light md:text-base leading-normal text-primary-neutral-500 text-center inter max-w-4xl capitalize">
                    Build Adaptable & Resilient identities through our knowledge
                    development areas and gain the adaptation advantage{" "}
                  </h4>
                </motion.div>
              </ScrollReveal>
            </div>
            <div class="flex flex-col w-full justify-center items-center text-center lg:max-w-3xl mx-auto mt-16">
              <motion.div
                ref={ref}
                variants={hexagonVariant_2}
                initial="hidden"
                animate={control_h1}
                className="flex flex-row items-center justify-center "
              >
                <img
                  src="/assets/FutureVersion/Home/adapt.png"
                  alt=""
                  className=" mx-auto"
                />
              </motion.div>
              {/*    <div class="w-full md:w-3/2 md:py-8 text-center flex flex-row items-center justify-center h-50v md:h-70v hexagon-wrapper">
              <div className="relative flex flex-row items-center justify-center bg-hexagon transform -rotate-90">
                 <motion.div
                    ref={ref}
                    variants={hexagonVariant}
                    initial="hidden"
                    animate={control}
                    className="centered-hexagon flex flex-row items-center justify-center hexagon p-5 "
                  >
                    <img
                      src="/assets/FutureVersion/Home/adaptationCenter.png"
                      alt=""
                      className="md:w-20 md:h-20 w-3/2 mx-auto"
                    />
                  </motion.div> 
                <motion.div
                    variants={hexagonVariant_1}
                    initial="hidden"
                    animate={control_3}
                    transition={{
                      duration: 0.75,
                      ease: "easeOut",
                      delay: 1,
                    }}
                    className="flex flex-col items-center justify-center hexagon absolute side-bl"
                  >
                    <img
                      src="/assets/FutureVersion/students/hex1.png"
                      alt=""
                      className="w-10 h-10 object-cover"
                    />
                    <p className="text-[#116E84] poppins font-medium text-sm leading-5 text-center p-4">
                      Being able to communicate
                    </p>
                  </motion.div>
                  <motion.div
                    variants={hexagonVariant_1}
                    initial="hidden"
                    animate={control_2}
                    transition={{
                      duration: 0.5,
                      ease: "easeOut",
                      delay: 0.75,
                    }}
                    className="flex flex-col items-center justify-center hexagon absolute side-tl"
                  >
                    <img
                      src="/assets/FutureVersion/students/hex2.png"
                      alt=""
                      className="w-10 h-10 object-cover"
                    />
                    <p className="text-[#4F57B7] poppins font-medium text-sm leading-5 text-center p-4">
                      Having Foresight
                    </p>
                  </motion.div>
                  <motion.div
                    variants={hexagonVariant_1}
                    initial="hidden"
                    animate={control_3}
                    transition={{
                      duration: 0.75,
                      ease: "easeOut",
                      delay: 1,
                    }}
                    className="side-hexagon flex flex-col items-center justify-center hexagon absolute side-br"
                  >
                    <img
                      src="/assets/FutureVersion/students/hex3.png"
                      alt=""
                      className="w-10 h-10 object-cover"
                    />
                    <p className="text-[#BA7507] poppins font-medium text-sm leading-5 text-center p-4">
                      Being able to produce
                    </p>
                  </motion.div>
                  <motion.div
                    variants={hexagonVariant_1}
                    initial="hidden"
                    animate={control_1}
                    transition={{
                      duration: 0.5,
                      ease: "easeOut",
                      delay: 0.25,
                    }}
                    className="side-hexagon flex flex-col items-center justify-center hexagon absolute side-t"
                  >
                    <img
                      src="/assets/FutureVersion/students/hex4.png"
                      alt=""
                      className="w-10 h-10 object-cover"
                    />
                    <p className="text-[#903795] poppins font-medium text-sm leading-5 text-center p-4">
                      Being able to learn & understand
                    </p>
                  </motion.div>
                  <motion.div
                    variants={hexagonVariant_1}
                    initial="hidden"
                    animate={control_4}
                    transition={{
                      duration: 0.5,
                      ease: "easeOut",
                      delay: 1.75,
                    }}
                    className="side-hexagon flex flex-col items-center justify-center hexagon absolute side-b"
                  >
                    <img
                      src="/assets/FutureVersion/students/hex5.png"
                      alt=""
                      className="w-10 h-10 object-cover"
                    />
                    <p className="text-[#008B46] poppins font-medium text-sm leading-5 text-center p-4">
                      Loving what you do
                    </p>
                  </motion.div>
                  <motion.div
                    variants={hexagonVariant_1}
                    initial="hidden"
                    animate={control_2}
                    transition={{
                      duration: 0.5,
                      ease: "easeOut",
                      delay: 1,
                    }}
                    className="side-hexagon flex flex-col items-center justify-center hexagon absolute side-tr"
                  >
                    <img
                      src="/assets/FutureVersion/students/hex6.png"
                      alt=""
                      className="w-10 h-10 object-cover"
                    />
                    <p className="text-[#CE5511] poppins font-medium text-sm leading-5 text-center p-4">
                      Having passion for what you do
                    </p>
                  </motion.div> 
                </div> 
               
              </div>*/}
            </div>
          </div>
        </div>
      </AnimatePresence>
    </>
  );
}

export default Programs;

function ProgramCard({ item, setInViewItem }) {
  const [ref, inView] = useInView();
  const { scrollYProgress } = useScroll();
  const control_1 = useAnimation();
  const control_2 = useAnimation();
  useEffect(() => {
    if (inView) {
      control_1.start("visible");
      setTimeout(() => {
        control_2.start("visible");
        setInViewItem(item?.lineColor);
      }, 200);
    }
    // else {
    //     control_2.start("hidden");

    //     control_1.start("hidden");
    // }
  }, [inView, scrollYProgress]);

  return (
    <li className="px-3 md:px-8 my-20 py-6 bg-white each-contract max-w-7xl mx-auto flex flex-col space-y-6 items-center w-full relative">
      <Fade triggerOnce>
        <span
          className={`poppins text-primary-caarya-medium text-[32px] md:text-[40px] w-full h-full justify-center items-center font-medium lg:leading-10 lg:tracking-[2px] inline-flex`}
        >
          <p> {item?.index}</p>
        </span>
      </Fade>
      <div className="space-y-12">
        <motion.div
          ref={ref}
          variants={cardsVariant}
          initial="hidden"
          animate={control_1}
          className=""
        >
          <div className="flex flex-col items-center justify-center space-y-4 w-full">
            <h1 class="text-2xl lg:text-3xl md:text-3.5xl font-medium lg:leading-[48px] text-black poppins lg:tracking-[6.4px] text-center">
              {item?.name}
            </h1>
            <p class="text-base inter md:text-[18px] leading-7 text-primary-neutral-800 inter text-center font-light">
              {item?.description}
            </p>
          </div>
        </motion.div>

        <motion.div
          variants={cardsVariant}
          initial="visible"
          animate={control_2}
          className="grid lg:grid-cols-2 gap-12"
        >
          <div
            className={`${
              !item?.title2 ? "col-span-2 max-w-[560px] mx-auto" : ""
            }`}
          >
            <Card {...item} />
          </div>

          {item?.title2 && (
            <Card
              title={item?.title2}
              programDescription={item?.programDescription2}
              image={item?.image2}
              bgGradient={item?.bgGradient2}
              textColor={item?.textColor2}
              recommend={item?.recommend2}
            />
          )}
        </motion.div>
      </div>
    </li>
  );
}
