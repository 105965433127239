import React from "react";
import NewsletterModal from "../NewsLetterModal";
import { storeEvent } from "App";

function AboutCGC() {
  const [showNewsletterModal, setShowNewsletterModal] = React.useState(false);

  return (
    <div className="py-8 inter">
      <div class="w-full flex flex-col items-center max-w-7xl mx-auto bg-blue-light lg:rounded-[5em] p-10 lg:p-14">
        <div class="flex flex-col space-y-10">
          <div className="flex flex-col items-center space-y-10 lg:space-y-0 lg:flex-row lg:space-x-20">
            <div class="flex flex-col w-full md:w-1/2 space-y-10">
              <h1 class="text-center md:text-left lg:leading-[48px] poppins text-2xl font-medium text-primary-caarya-medium md:text-[32px]">
                <span class="text-primary-peddle-500">Introducing </span> <br />{" "}
                Caarya Growth Centers
              </h1>

              <p class="inter text-primary-neutral-800 tracking-[0.8px] leading-7">
                Caarya Growth Centers (CGCs) represent{" "}
                <span class="text-primary-caarya-medium font-semibold">
                  vibrant microcosms of the broader Caarya ecosystem{" "}
                </span>
                , city-based hubs fostering dynamic interaction among startups,
                students, skill experts, career makers, educators, and
                gig-workers.
              </p>
              <p class="inter text-primary-neutral-800 tracking-[0.8px] leading-7">
                These centers are dedicated to exploring and shaping three vital
                facets of the contemporary and future world -{" "}
                <span class="text-primary-caarya-medium font-semibold">
                  the future of careers, the future of education, and the future
                  of work.
                </span>
              </p>
            </div>
            <img
              class="rounded-xl lg:h-96"
              src="/assets/landingvector_trial.png"
              alt=""
            />
          </div>
          <div class="flex flex-col space-y-10 w-full items-center">
            <p class="inter text-primary-neutral-800 tracking-[0.8px] leading-7">
              Here, students are not mere observers; they are active
              participants,{" "}
              <span class="text-primary-caarya-medium font-semibold">
                engaging in short-term work commitments with startups
              </span>
              , acquiring new skills, and gaining practical experience that sets
              the stage for their career paths.
            </p>
            <p class="inter text-primary-neutral-800 tracking-[0.8px] leading-7">
              By providing a platform for such enriched interactions, CGCs not
              only catalyze the growth of startups but also empower students and
              professionals to{" "}
              <span class="text-primary-caarya-medium font-semibold">
                adapt, evolve, and thrive{" "}
              </span>{" "}
              in the ever-changing landscape of work and education.
            </p>
            <NewsletterModal
              isOpen={showNewsletterModal}
              closeModal={() => setShowNewsletterModal(false)}
            />
            <button
              onClick={() => {
                storeEvent({
                  category: "About",
                  action: "redirect",
                  label: "CGCs",
                });
              }}
              class="inter mb-10 mx-auto md:mx-0 hover:underline yellow-btn text-primary-neutral-800 font-semibold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            >
              Check out our CGCs ->
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutCGC;
