import React from "react";
import Form from "./Form";
import Info from "./Info";

const HelpCenterContent = ({}) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 items-center my-20 md:mx-24 mx-3">
            <div className=" order-last md:order-first">
                <Info />
            </div>
            <div>
                <Form className=" order-first md:order-last" />
            </div>
        </div>
    );
};

export default HelpCenterContent;
