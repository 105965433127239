import { useEffect } from "react";
import CuriousProLatest from "Components/FutureVersion/BlogComponents/CuriousProLatest";
import TSP from "Components/FutureVersion/BlogComponents/TSP";
import ReelBanner from "Components/FutureVersion/ReelComponents/ReelBanner";
import ReelFeatured from "Components/FutureVersion/ReelComponents/ReelFeatured";
import ReelFollowUs from "Components/FutureVersion/ReelComponents/ReelFollowUs";
import ReelNewsLetter from "Components/FutureVersion/ReelComponents/ReelNewsLetter";
import AcrossNetwork from "Components/FutureHomeComponents/AcrossNetwork";
import FHomeBannerNav from "Components/Common/FHomeBannerNav";
import Footer from "Components/Common/Footer";
import ThePodcast from "Components/FutureVersion/ReelComponents/ThePodcast";
import Loader from "Components/Common/Loader";
import { useRouteMatch } from "react-router-dom";
import { initializeReactGA } from "App";

function FHighlightReel() {
  const route = useRouteMatch();
  useEffect(() => {
    initializeReactGA("Media");
    window.scrollTo({ top: 0 });
    setTimeout(() => {
      const section =
        new URLSearchParams(window.location.search).get("section") || "";

      if (section !== "") {
        document.querySelector(`#${section}`).scrollIntoView({
          behavior: "smooth",
          alignToTop: true,
        });
        console.log("scrolled to section " + section);
      }
    }, 100);
  }, []);
  return (
    <div>
      {!localStorage.getItem(route?.path) && <Loader />}
      <FHomeBannerNav />
      <ReelBanner />
      <div className="" id="caaryamonthly">
        <ReelNewsLetter />
      </div>
      <ThePodcast />
      {/* <TSP /> */}
      <ReelFeatured />
      <CuriousProLatest />
      <AcrossNetwork />
      <ReelFollowUs />
      <Footer />
    </div>
  );
}

export default FHighlightReel;
