import axios from "axios";
import { CHRONOS_ENDPOINT, ENDPOINT } from "..";

const BASE_API_URL = CHRONOS_ENDPOINT;
const PUBLIC_ENDPOINT = BASE_API_URL + "/api/v1/public/website";
const LIFE_ENDPOINT = BASE_API_URL + "/api/v1/app/life/";
const CAARYA_ME_ENDPOINT = ENDPOINT + "/api/v1/";

export { BASE_API_URL, PUBLIC_ENDPOINT, LIFE_ENDPOINT, CAARYA_ME_ENDPOINT };

export const lifeInstance = axios.create({
  baseURL: LIFE_ENDPOINT,
});

export const caaryaMeInstance = axios.create({
  baseURL: CAARYA_ME_ENDPOINT,
});
