import { Link, useLocation } from "react-router-dom";

const LinkComponent = ({ title, link, dark }) => {
    const { pathname: path } = useLocation();

    return (
        <li class="md:nav-item">
            <Link
                to={link}
                class={`nav-text px-3 py-6 md:py-1 flex items-center text-xl poppins md:text-sm leading-snug ${
                    path.indexOf(link) > -1 ? "underline" : ""
                } text-black ${dark ? "md:text-white" : ""} cursor-pointer`}
            >
                {title}
            </Link>
        </li>
    );
};
export default LinkComponent;
