import { Link } from "react-router-dom";

import "./style.css";

/* This example requires Tailwind CSS v2.0+ */
const navigation = {
  Explore: [
    { name: "About", href: "/aboutcaarya" },
    { name: "Media", href: "/highlightreel" },
    // { name: "Testimonials", href: "/facesofcaarya" },
  ],
  // Programs: [
  //   {
  //     name: "Professional Learner Program",
  //     href: "/offerings/Career Kickstarter School",
  //   },
  //   {
  //     name: "Leadership Accelerator Program",
  //     href: "/offerings/caaryacommunities",
  //   },
  //   { name: "Work Scholarship Program", href: "/offerings/caaryaworkstudy" },
  //   { name: "Fusion Industial Contracts", href: "/offerings/Industry Safari" },
  // ],
  // "Quick Links": [
  //   {
  //     name: "Caarya Growth Centers",
  //     target: "blank",
  //     href: "https://caarya.typeform.com/own-ur-edu",
  //   },
  //   { name: "Caarya Blog", href: "/thecuriouspro" },
  //   { name: "The Curious Pro", href: "/highlightreel" },
  //   { name: "The Student Podcast", href: "/buildyourownidentity" },
  // ],
};

export default function Footer() {
  return (
    <footer
      className=""
      style={{ backgroundColor: "#f9f9f9" }}
      aria-labelledby="footer-heading"
    >
      <div className="max-w-7xl mx-auto py-8 px-6 sm:px-4 lg:py-12 lg:px-6">
        <div className="my-12 flex flex-row space-x-10 lg:space-x-40 justify-between lg:justify-center xl:mt-0">
          {Object.keys(navigation).map((e) => {
            return (
              <div className="mb-4">
                <h3
                  className="text-lg font-medium text-primary-neutral-500 poppins"
                  style={{
                    color: "",
                  }}
                >
                  {e}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation[e].map((item) => (
                    <li key={item.name}>
                      {item.target === "_blank" ? (
                        <a
                          className="footer-item text-primary-neutral-800 inter tracking-tight text-xs font-light"
                          href={item.href}
                          target="blank"
                        >
                          {" "}
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          to={item.href}
                          className="footer-item text-primary-neutral-800 hover:red-400 inter tracking-tight text-xs font-light"
                        >
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
          <div>
            <h3
              className="text-lg font-medium aqua-400 poppins tracking-tight"
              style={{
                color: "",
              }}
            >
              Contact Us
            </h3>
            <ul role="list" className="mt-4 space-y-4">
              <li>
                <a
                  className="text-sm font-semibold text-primary-neutral-800 inter tracking-tight"
                  style={{ color: "" }}
                >
                  Write us an email
                </a>
              </li>
              <li>
                <a
                  className="flex items-center footer-item cursor-pointer"
                  style={{ color: "" }}
                >
                  <img
                    src="/assets/mail.svg"
                    className="h-8 w-8 pr-2 footer-image"
                  />
                  <div class="text-primary-caarya-medium">hello@caarya.in</div>
                </a>
              </li>
              <li>
                <a
                  className="text-sm font-semibold text-primary-neutral-800 inter tracking-tight"
                  style={{ color: "" }}
                >
                  Contact us on Social Media
                </a>
              </li>
              <li>
                <a
                  className="flex items-center text-base hover:text-primary-neutral-800"
                  style={{ color: "" }}
                >
                  <a
                    href="https://www.instagram.com/caarya.in/?igshid=MDM4ZDc5MmU%3D"
                    target="blank"
                  >
                    <img
                      src="/assets/insta_footer.svg"
                      className="h-6 w-6 mr-4 footer-image"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/caarya/"
                    target="blank"
                  >
                    <img
                      src="/assets/linkedin_footer.svg"
                      className="h-6 w-6 footer-image"
                    />
                  </a>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

//Old Footer
// function Footer() {
//   return (
//     <div className="flex website-bg flex-col lg:flex-row px-0 md:px-10 md:justify-between mb-3 lg:mb-0">
//       <p class=" footer-text inter p-5 text-gray-600 text-center lg:text-left">
//         {" "}
//         Made with ♥ at Caarya @ 2021{" "}
//       </p>
//       <div class="mx-auto lg:mx-0 flex flex-wrap justify-center inter p-5 text-gray-600 text-center md:text-right">
//         <Link className="mx-2" to="/">
//           Home
//         </Link>{" "}
//         .{" "}
//         <Link className="mx-2" to="/caaryaverse">
//           Programs
//         </Link>{" "}
//         .{" "}
//         <Link className="mx-2" to="highlightreel">
//           News
//         </Link>{" "}
//         .
//         <Link className="mx-2" to="/facesofcaarya">
//           Testimonials
//         </Link>{" "}
//         .{" "}
//         <Link className="mx-2" to="/thecuriouspro">
//           Blog
//         </Link>
//         <Link className="mx-2" to="/comingsoon">
//           Coming Soon
//         </Link>
//       </div>
//     </div>
//   );
// }

// export default Footer;
