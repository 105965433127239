import {
  AcademicCapIcon,
  CubeTransparentIcon,
  CurrencyRupeeIcon,
} from "@heroicons/react/outline";

import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
const features = [
  {
    name: "Caarya Educational Services",
    description:
      "Concept of Work Study is  new in India and while many do not know about it, this very opportunity has made many Indian students stay ahead of their time and industry ready without missing classes or assignments.",
    stat: "20",
    icon: CubeTransparentIcon,
    image: "/assets/FutureVersion/Home/About/1.png",
  },

  {
    name: "Caarya Talent Services",
    description:
      "The connection between various departments is enabled by communication between mentors and mentees. Unlike once a month seminars in colleges or only online webinars, Caarya provides one of a kind chance to directly work with experts.",
    stat: "30+",
    image: "/assets/FutureVersion/Home/About/2.png",
    icon: CurrencyRupeeIcon,
  },

  {
    name: "Caarya Venture Services",
    description:
      "Apart from us being Startup India certified our association with students of reputed colleges like GITAM Vizag, BITS Goa, Christ University, Woxen School of Art and Design, BITS Hyderabad and St. Xavier’s College Kolkata has helped us to polish the bridge between future industry demands and student needs.",
    stat: "20",
    image: "/assets/FutureVersion/Home/About/3.png",
    icon: AcademicCapIcon,
  },
];
const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "60%" },
};

function HomeStats() {
  return (
    <div class="bg-red-light py-8 inter w-full mx-auto">
      <section class="container mx-auto text-center pb-24">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              variants={cardsVariant}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
            >
              <h1 class="pt-24 poppins w-full mb-3 text-xl lg:text-[32px] font-medium leading-tight lg:tracking-[3.2px] text-center text-gray-800">
                Our <span class="text-primary-peddle-500">Business</span>
                <span class="text-primary-caarya-medium"> Verticals </span>
              </h1>
            </motion.div>
          )}
        </InView>

        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 lg:gap-x-12 md:gap-y-10">
          {features.map((feature) => (
            <div
              key={feature.name}
              className="relative  bg-white rounded-3xl py-8 px-6 flex flex-col items-stretch space-y-5"
            >
              <img
                src={feature?.image}
                alt=""
                className="vertical-image object-cover"
              />
              <h1 className="inter text-lg font-semibold leading-7 text-center">
                {feature?.name}
              </h1>
              <p className="text-primary-neutral-500 inter font-light text-base leading-6">
                {feature?.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default HomeStats;
