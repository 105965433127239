import { lifeInstance } from ".";

export const getColleges = (query) =>
  lifeInstance.get(
    `/colleges${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`
  );
