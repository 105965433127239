function BlogBanner() {
	return (
		//Blog Banner HTML
		<div className="bg-blue-light py-8 inter w-full mx-auto container-b-rounded">
			<div class="w-full pt-48 text-center md:hidden">
				{/* <Polaroid src="/assets/thecuriouspro.jpg" /> */}
				<img className="mx-auto w-96" src="/assets/curious_pro.png" />
			</div>
			<div class="pt-6 pb-10 md:pb-0">
				<div class="container px-4 md:px-20 mx-auto md:my-8 md:mb-16 flex flex-wrap flex-col md:flex-row items-center">
					<div class="flex flex-col w-full md:w-1/2 justify-center items-start text-center md:text-left">
						<h5 class="my-4 md:mt-32 w-full text-md md:text-xl font-bold leading-tight aqua-400 inter">
							Episode 1
						</h5>
						<h1 class="my-0 text-3xl md:text-4xl w-full font-bold leading-tight red-400 poppins">
							The Curious Pro
						</h1>
						<p class="mt-4 leading-normal text-xl md:text-xl mb-8 text-gray-800 inter">
							It's the pandemic and working/studying from home is no good. Read
							the musings of a bunch of 20 year olds go through their struggles.
							There just does not seem to be enough time.
						</p>
					</div>
					<div class="my-4 md:mt-32 w-full md:w-1/2 text-center hidden md:block">
						<img className="ml-auto w-96 p-3" src="/assets/curious_pro.png" />
						{/* <Polaroid src="/assets/gaurav_ig.png" />
            <Polaroid src="/assets/gaurav_ig.png" />
            <Polaroid src="/assets/gaurav_ig.png" /> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default BlogBanner;
