import { useEffect, useRef, useState } from "react";
import { getColleges } from "config/axios/colleges";
import { enrollStudent } from "config/axios/enroll";
import { ArrowRight, X } from "@phosphor-icons/react";
import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/solid";
import { storeEvent } from "App";
let dv = {
  name: "",
  email: "",
  phone: "",
  college: {
    id: "",
    college_name: "",
    college_location: "",
  },
};
function Apply() {
  const [inputData, setInputData] = useState(dv);
  const [newCollege, setNewCollege] = useState(false);
  const [showCollegesDropdown, setShowCollegesDropdown] = useState(false);
  const [showCollegesLocationDropdown, setShowCollegesLocationDropdown] =
    useState(false);
  const [colleges, setColleges] = useState([]);
  const [filteredItems, setFilteredItems] = useState(colleges);
  const [filteredLocationItems, setFilteredLocationItems] = useState(state);

  const dropdownRef = useRef(null);
  const dropdownLocationRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowCollegesDropdown(false);
    }
    if (
      dropdownLocationRef.current &&
      !dropdownLocationRef.current.contains(event.target)
    ) {
      setShowCollegesLocationDropdown(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: response } = await getColleges();
        setColleges(response.data);
        setFilteredItems(response.data);
      } catch (err) {
        console.log(err);
      }
    })();

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const enroll = async () => {
    try {
      const body = {
        ...inputData,
        fromForm: "Website",
      };
      if (newCollege) {
        body.college.isNew = true;
        body.college.college_name = body.college.new_college_name;
      }
      const { data: response } = await enrollStudent(body);
      if (response) {
        setInputData(dv);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        style={{
          background:
            window.innerWidth < 1024
              ? "url('/assets/mobileEnroll.png')"
              : "url('/assets/enroll.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundPositionY: 0,
        }}
        className="px-5 pt-20 pb-20 relative mt-10"
      >
        <div className="max-w-2xl mx-auto w-full px-2.5 flex flex-col items-center space-y-8">
          <div className="py-3 flex flex-col items-center space-y-2">
            <h1 className="text-white poppins text-[20px] lg:leading-[48px] lg:tracking-[3.2px] text-center leading-7 tracking-[2px] lg:text-[32px] font-light">
              Get a Caarya Life Membership
            </h1>
            <p className="text-white inter text-sm text-center lg:text-base lg:leading-6 lg:tracking-[0.4px] leading-5 tracking-[0.35px] font-light">
              To Kickstart Your Professional Career{" "}
            </p>
          </div>

          <a
            href="https://apply.caarya.life/AI5"
            target="__blank"
            className="bg-black bg-opacity-80 rounded-full shadow-inner py-3 px-8 flex flex-row items-center space-x-2 justify-center text-white inter text-base font-semibold cursor-pointer"
            onClick={() => {
              storeEvent({
                category: "Students",
                action: "redirect",
                label: "AI5 Assessment",
              });
            }}
          >
            <p className="">Take Our AI5 Assessment</p>
            <ArrowRight size={16} />
          </a>
        </div>
      </div>
    </>
  );
}

export default Apply;

let state = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
