import { ChevronRightIcon } from "@heroicons/react/solid";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation, useScroll } from "framer-motion";
const activeSectionVariant = {
    active: {
        backgroundColor: "#fcf0f1",
        transition: {
            duration: 1,
            backgroundColor: {
                duration: 2,
            },
            ease: "easeInOut",
        },
    },
    inactive: {
        backgroundColor: "white",
        transition: {
            duration: 1,
            backgroundColor: {
                duration: 2,
            },
            ease: "easeInOut",
        },
    },
};
function HelpCenter({ onButtonClick }) {
    const [activeRef, isActiveSection] = useInView();
    return (
        //Home Banner HTML
        <motion.div
            variants={activeSectionVariant}
            initial="inactive"
            animate={
                isActiveSection
                    ? activeSectionVariant.active
                    : activeSectionVariant.inactive
            }
            className="py-8 inter w-full mx-auto"
        >
            <div className="w-full pt-10 text-center md:hidden">
                <img
                    className="w-10/12 mx-auto"
                    src="/assets/FutureVersion/Home/helpCenterCover.png"
                    alt=""
                />
            </div>
            <div className="pt-6 pb-10 md:pb-0">
                <div className="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                    <div
                        ref={activeRef}
                        className="flex flex-col w-full md:w-1/2 justify-center items-center md:items-start text-center md:text-left"
                    >
                        <h1 className="my-4 text-2xl md:text-3.5xl font-semibold leading-tight text-black poppins">
                            Help Center
                        </h1>
                        <p className="leading-6 text-base mb-8 text-black inter md:w-11/12">
                            Whether you're curious about features, have any
                            feedbacks or want to connect with our coordinators -
                            we're here to answer any and all questions.
                        </p>
                        <button className="getProfiledBtn flex gap-4 items-center poppins font-semibold text-sm leading-5">
                            Contact Us <ChevronRightIcon className="w-5" />
                        </button>
                    </div>

                    <div className="md:w-1/2 md:py-14  text-center hidden md:block">
                        <img
                            className=" mx-auto"
                            src="/assets/FutureVersion/Home/helpCenterCover.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default HelpCenter;
