import { ArrowUpIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";

function ScrollToTop({ history }) {
  const [isVisible, setIsVisible] = useState(false);
  const [animation, setAnimation] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  useEffect(() => {
    // const unlisten = history.listen(() => {
    //     window.scrollTo(0, 0);
    // });
    // return () => {
    //     unlisten();
    // };
  }, []);

  return (
    <div className="fixed cursor-pointer bottom-0 right-0 scroll-to-top z-40 p-8">
      {isVisible && (
        <div
          onMouseEnter={() => setAnimation(true)}
          onMouseLeave={() => setAnimation(false)}
          onClick={scrollToTop}
          className="w-12 lg:w-16 h-12 lg:h-16 rounded-full flex flex-row items-center justify-center bg-white border border-primary-neutral-100 shadow-lg"
        >
          <ArrowUpIcon
            className={`text-primary-neutral-500 w-5 lg:w-7 h-5 lg:h-7 ${
              animation ? "animate-bounce" : ""
            } `}
          />
        </div>
      )}
    </div>
  );
}

export default ScrollToTop;
