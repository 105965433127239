import { ScrollReveal } from "Components/Common/Helpers/ScrollReveal";
import { useScroll, motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "60%" },
};

function StudentBenefits() {
  const [scrollOffset, setScrollOffset] = useState(-150);
  const [ref2, inView2] = useInView();
  const control_h = useAnimation();
  useEffect(() => {
    console.log("inView", inView2);
    if (inView2) {
      control_h.start("visible");
    }
  }, [inView2]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-300);
    } else {
      setScrollOffset(-500);
    }
  }, []);
  return (
    <>
      <div class="py-14 relative">
        <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="w-full flex flex-col items-center justify-center space-y-2 lg:space-y-4">
            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="hidden"
                animate={control_h}
                className="mt-6 py-4 w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-xs font-light md:text-sm uppercase px-6 leading-normal text-primary-peddle-500 tracking-[1.2px] lg:tracking-[1.4px] text-center poppins pb-2 border-b-2 lg:border-b-4 w-max border-primary-caarya-medium">
                  Become A Member
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-2xl font-light md:text-[32px] px-6 leading-9 tracking-[2.4px] lg:tracking-[3.2px] lg:leading-[48px] text-black text-center poppins w-max">
                  Register With A
                  <br />
                  Caarya Growth Centre
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-sm font-light md:text-base leading-normal text-primary-neutral-500 text-center inter max-w-4xl capitalize">
                  Benefits and key takeaways include{" "}
                </h4>
              </motion.div>
            </ScrollReveal>
          </div>
          <div className="w-full relative py-12 grid grid-cols-2 lg:grid-cols-3 gap-x-5 lg:gap-x-16 gap-y-12 lg:gap-y-20">
            {[
              {
                name: "Exclusive Startup Connections",
                description:
                  "Network with startups hungry for exceptional talent, positioning yourself at theforefront of innovation.",
                image: "/assets/FutureVersion/students/1.svg",
              },
              {
                name: "Influence Future Leaders",
                description:
                  "Shape the careers of emerging talent, becoming a catalyst for their success.",
                image: "/assets/FutureVersion/students/2.svg",
              },
              {
                name: "Building High-Value Networks",
                description:
                  "Get an opportunity to foster strong connections with top talent, startup founders, industry experts and diverse professionals.",
                image: "/assets/FutureVersion/students/3.svg",
              },
              {
                name: "Interdisciplinary Collaborations",
                description:
                  "Collaborate with professionals spanning various industries and sectors, broadening your horizons.",
                image: "/assets/FutureVersion/students/4.svg",
              },
              {
                name: "Getting Startup-ready",
                description:
                  "Dive into hyper-agile startup environments, mastering adaptability and problem-solving, ensuring you're ready to lead in Industry 5.0.",
                image: "/assets/FutureVersion/students/5.svg",
              },
              {
                name: "Kickstart your professional career",
                description:
                  "Hands-on remote work experience, honing your collaboration skills and preparing for the future of distributed workplaces.",
                image: "/assets/FutureVersion/students/6.svg",
              },
              {
                name: "Build fluid career pathways",
                description:
                  "Cultivate a versatile skillset, evolving as a neo-generalist, equipping you to navigate diverse career paths in the ever-changing landscape.",
                image: "/assets/FutureVersion/students/7.svg",
              },
              {
                name: "Enhance your human potential",
                description:
                  "Unlock your human potential by acquiring transferable skills, empowering you to excel in any role and fostering personal growth.",
                image: "/assets/FutureVersion/students/8.svg",
              },
              {
                name: "Access to a Nationwide Talent Network",
                description:
                  "Connect and collaborate with talented students from diverse backgrounds across India.",
                image: "/assets/FutureVersion/students/9.svg",
              },
            ]?.map((item) => {
              return <Card item={item} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentBenefits;

function Card({ item }) {
  const [ref, inView] = useInView();
  const { scrollYProgress } = useScroll();
  const control_1 = useAnimation();
  const control_2 = useAnimation();
  useEffect(() => {
    if (inView) {
      control_1.start("visible");
      setTimeout(() => {
        control_2.start("visible");
      }, 200);
    }
  }, [inView, scrollYProgress]);

  return (
    <motion.div
      ref={ref}
      variants={cardsVariant}
      initial="hidden"
      animate={control_1}
      className="flex flex-col items-center justify-start space-y-3"
    >
      <img
        src={item?.image}
        alt=""
        className="lg:w-20 h-16 w-16 lg:h-20 object-contain"
      />
      <div className="flex flex-col items-center justify-center space-y-3 w-full">
        <h1 class="text-sm lg:text-base font-semibold lg:leading-6 text-black inter lg:tracking-[0.8px] text-center">
          {item?.name}
        </h1>
        <p class="text-xs lg:text-sm inter text-primary-neutral-800 leading-5 text-center font-light">
          {item?.description}
        </p>
      </div>
    </motion.div>
  );
}
