import React from "react";

const HelpCenterInfo = () => {
  return (
    <div
      className="poppins  flex flex-col px-10 mt-5 md:mt-0"
      style={{ color: "#2B1111" }}
    >
      <div>
        <p className=" text-4xl font-semibold">Have a Question?</p>
        <p className=" text-sm">We have the answers, let us help!</p>
      </div>
      <div className=" mt-16">
        <p className=" text-2xl font-semibold">Get In Touch</p>
        <div className=" flex items-center mt-4">
          <img src="/assets/HelpCenter/mail.png" className=" mr-2" />
          hello@caarya.in
        </div>
        {/* <div className=" flex items-center mt-3">
          <img src="/assets/HelpCenter/phone.png" className=" mr-2" />
          +91-9900990999
        </div> */}
        <div className="flex mt-4">
          <div>
            <img src="/assets/HelpCenter/instagram.png " />
          </div>
          <div>
            <img src="/assets/HelpCenter/linked-in.png" className=" ml-4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCenterInfo;
