import {
  AdjustmentsIcon,
  CubeTransparentIcon,
  CurrencyRupeeIcon,
  FastForwardIcon,
  FireIcon,
  HeartIcon,
  LightBulbIcon,
  LinkIcon,
  MicrophoneIcon,
} from "@heroicons/react/outline";
import { ScrollReveal } from "Components/Common/Helpers/ScrollReveal";
import { useScroll, motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "60%" },
};
function AboutFocusPoints() {
  const [scrollOffset, setScrollOffset] = useState(-150);
  const [ref2, inView2] = useInView();
  const control_h = useAnimation();
  useEffect(() => {
    console.log("inView", inView2);
    if (inView2) {
      control_h.start("visible");
    }
  }, [inView2]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-300);
    } else {
      setScrollOffset(-500);
    }
  }, []);
  return (
    //Focus Points
    <div class="">
      <section class="website-bg my-8">
        <ScrollReveal offset={scrollOffset}>
          <motion.div
            ref={ref2}
            variants={cardsVariant}
            initial="hidden"
            animate={control_h}
          >
            <h1 class=" mt-16 poppins w-full text-xl  font-medium leading-[48px] tracking-[3.2px] text-center">
              Our <span class="text-primary-peddle-500">Focus</span> Points
            </h1>
          </motion.div>
        </ScrollReveal>
        <div class="w-full mb-4 mt-4">
          <div class="h-1 mx-auto website-bg w-1/6 my-0 py-0 rounded-t"></div>
        </div>
        <div class="max-w-5xl px-6 pb-8 mx-auto inter">
          <div class="grid gap-8 mt-10 md:grid-cols-2 lg:grid-cols-3">
            <div class="px-6 py-8 overflow-hidden bg-blue-light rounded-3xl">
              <h2 class="text-lg font-semibold text-primary-caarya-medium">
                <div className="mb-4">
                  <AdjustmentsIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                Choice Based Education
              </h2>
              <p class="max-w-md mt-4 text-primary-neutral-500 inter font-light text-sm leading-5 tracking-[0.7px]">
                To help students kickstart meaningful careers by supplementing
                their passion, professional choices, vocational choices and
                goals with learning experiences.
              </p>
            </div>
            <div class="px-6 py-8 overflow-hidden bg-blue-light rounded-3xl">
              <h2 class="text-lg font-semibold leading-8 text-primary-blue-500">
                <div className="mb-4">
                  <CubeTransparentIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                Cross Domain T Skills
              </h2>
              <p class="max-w-md mt-4 text-primary-neutral-500 inter font-light text-sm leading-5 tracking-[0.7px]">
                To teach cross domain t-skills first hand and equip students
                with knowledge required in today’s economy. Mentored by our
                successful industry professionals, cross domain skills are
                mandatory for jobs today.
              </p>
            </div>

            <div class="px-6 py-8 overflow-hidden bg-blue-light rounded-3xl">
              <h2 class="text-lg font-semibold leading-8 text-primary-fusion-dark">
                <div className="mb-4">
                  <FastForwardIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                Students for Startups
              </h2>
              <p class="max-w-md mt-4 text-primary-neutral-500 inter font-light text-sm leading-5 tracking-[0.7px]">
                To open up our network of successful startups to students and
                help them gain work experience while in college and get started
                as working student professionals.
              </p>
            </div>

            <div class="px-6 py-8 overflow-hidden bg-blue-light rounded-3xl">
              <h2 class="text-lg font-semibold leading-8 text-primary-blue-500">
                <div className="mb-4">
                  <MicrophoneIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                Participatory Learning
              </h2>
              <p class="max-w-md mt-4 text-primary-neutral-500 inter font-light text-sm leading-5 tracking-[0.7px]">
                To nurture community driven learning experiences that give a
                safe space for students to grow and express themselves.
              </p>
            </div>

            <div class="px-6 py-8 overflow-hidden bg-blue-light rounded-3xl">
              <h2 class="text-lg font-semibold text-primary-caarya-medium">
                <div className="mb-4">
                  <FireIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                The Caarya Network
              </h2>
              <p class="max-w-md mt-4 text-primary-neutral-500 inter font-light text-sm leading-5 tracking-[0.7px]">
                To provide network across our community of promoters, leaders,
                accelerators, mentors, accountability partners and teammates.
                Create lifelong bonds of learning and friendship here.
              </p>
            </div>

            <div class="px-6 py-8 overflow-hidden bg-blue-light rounded-3xl">
              <h2 class="text-lg font-semibold leading-8 text-primary-blue-500">
                <div className="mb-4">
                  <LinkIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                Collaborative Leadership
              </h2>
              <p class="max-w-md mt-4 text-primary-neutral-500 inter font-light text-sm leading-5 tracking-[0.7px]">
                To encourage leadership and innovation in student communities
                where they can collaborate with their peers across multiple
                domains.
              </p>
            </div>

            <div class="px-6 py-8 overflow-hidden bg-blue-light rounded-3xl">
              <h2 class="text-lg font-semibold leading-8 text-primary-fusion-dark">
                <div className="mb-4">
                  <LightBulbIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                Student Entrepreneurship
              </h2>
              <p class="max-w-md mt-4 text-primary-neutral-500 inter font-light text-sm leading-5 tracking-[0.7px]">
                To give voice and credibility to student contributions in
                college and enable their efforts to be recognized pan India.
              </p>
            </div>

            <div class="px-6 py-8 overflow-hidden bg-blue-light rounded-3xl">
              <h2 class="text-lg font-semibold leading-8 text-primary-blue-500">
                <div className="mb-4">
                  <HeartIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                A Community for Change
              </h2>
              <p class="max-w-md mt-4 text-primary-neutral-500 inter font-light text-sm leading-5 tracking-[0.7px]">
                To promote awarness about social issues and help students use
                their skills to work on impact projects.
              </p>
            </div>

            <div class="px-6 py-8 overflow-hidden bg-blue-light rounded-3xl">
              <h2 class="text-lg font-semibold text-primary-caarya-medium">
                <div className="mb-4">
                  <CurrencyRupeeIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                The Student Currency
              </h2>
              <p class="max-w-md mt-4 text-primary-neutral-500 inter font-light text-sm leading-5 tracking-[0.7px]">
                To promote a culture where in student contributions can be
                monetized, validated and accounted for.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutFocusPoints;
