import './style.css';

function VerseAnimation() {
	return (
		<div class="ml-40 verse-container w-48 sm:w-96">
			<div class="flex flex-wrap flex-row items-center  row-logos">
				<div class="w-1/3  align-left content-center logo-col">
					<img
						class="verse-vertical-logo exp-logo mx:auto w-full"
						src="/logos/verse-exp.svg"
						alt="howdy"
					/>
				</div>
				<div class="w-1/3 border-col">
					<div class="h-1  ed-gradient ht-gradient w-30 my-0 py-0 rounded-t mr-18"></div>
				</div>
				<div class="w-1/3 logo-col">
					<img
						class="verse-vertical-logo dawn-logo w-full mx:auto"
						src="/logos/verse-dawn.svg"
						alt="howdy"
					/>
				</div>
			</div>
			<div class="flex flex-wrap flex-row row-borders -mt-2 sm:-mt-5">
				<div class="w-1/3">
					<div class="h-16 le-gradient  w-1  my-0  rounded-t transform logo-rotate-tl -ml-4"></div>
				</div>
				<div class="w-1/3"></div>
				<div class="w-1/3">
					<div class="h-16 ds-gradient w-1  my-0 py-0 rounded-t  transform logo-anti-rotate-tr float-right -mr-4"></div>
				</div>
			</div>

			<div class="flex flex-wrap flex-row items-center row-logos -mt-2  sm:-mt-5">
				<div class="w-1/3 logo-col">
					<img
						class="verse-vertical-logo labs-logo -ml-12 sm:-ml-20"
						src="/logos/verse-labs.svg"
						alt="howdy"
					/>
				</div>
				<div class="w-1/3 logo-col">
					<img
						className="verse-main-logo "
						src="/logos/verse-caarya.svg"
						alt="howdy"
					/>
				</div>
				<div class="w-1/3  logo-col">
					<img
						class="verse-vertical-logo symmetry-logo -mr-12 sm:-mr-20  float-right"
						src="/logos/verse-symmetry.svg"
						alt="howdy"
					/>
				</div>
			</div>

			<div class="flex flex-wrap flex-row items-center row-borders -mt-2  sm:-mt-5">
				<div class="w-1/3">
					<div class="h-16 fl-gradient w-1  my-0 py-0 rounded-t transform logo-anti-rotate-bl -ml-4"></div>
				</div>
				<div class="w-1/3 "></div>
				<div class="w-1/3 ">
					<div class="h-16 sw-gradient w-1  my-0 py-0 rounded-t  transform logo-rotate-br float-right -mr-4 "></div>
				</div>
			</div>

			<div class="flex flex-wrap flex-row items-center row-logos -mt-2  sm:-mt-4">
				<div class="w-1/3 logo-col">
					<img
						class="verse-vertical-logo fusion-logo mx:auto w-full"
						src="/logos/verse-fusion.svg"
						alt="howdy"
					/>
				</div>
				<div class="w-1/3 mb-10 border-col">
					<div class="h-1 wf-gradient hb-gradient w-30  my-0 py-0 rounded-t"></div>
				</div>
				<div class="w-1/3 logo-col">
					<img
						class="verse-vertical-logo wave-logo mx:auto w-full"
						src="/logos/verse-wave.svg"
						alt="howdy"
					/>
				</div>
			</div>
		</div>
	);
}

export default VerseAnimation;
