import { ChevronDownIcon } from "@heroicons/react/solid";
import "./style.css";

import Lottie from "lottie-react";
import arrowsAnimation from "./red.json";
function StartupBanner() {
  return (
    //Home Banner HTML
    <div
      id="startupBanner"
      className="pb-10 pt-20 inter w-full mx-auto relative h-screen startupBanner"
    >
      {/* <img
        class=" absolute bottom-0 right-0"
        alt=""
        src="/assets/FutureVersion/students/newHero.png"
      /> */}

      <div class="h-full flex flex-col items-center lg:justify-center relative">
        <div class="relative container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div class="py-12 lg:py-6 space-y-10 max-w-3xl flex flex-col w-full justify-center items-start text-center md:text-left">
            <div className="flex flex-col space-y-2 lg:space-y-4">
              <p className="poppins text-sm text-left lg:text-2xl font-medium text-primary-caarya-medium lg:tracking-[1.6px] lg:leading-[38px]">
                caarya
              </p>
              <h1 class="text-base md:text-[32px] uppercase leading-[19px] tracking-[4.9px] font-medium lg:leading-[38.4px] text-black poppins lg:tracking-[1.6px] lg:capitalize">
                For Startups
              </h1>
            </div>
            <div className="relative space-y-2 lg:space-y-4">
              <p class="lg:leading-[64px] leading-[48px]  tracking-[1.6px] text-left lg:tracking-[2.56px] text-[20px] lg:text-[32px] font-light text-black inter md:w-11/12">
                Striving to Solve the 21st Century’s Great Talent Problem
              </p>
              <p class="lg:leading-[40px] leading-7 tracking-[1.12px] text-left lg:tracking-[1.6px] text-sm lg:text-xl font-light text-black inter md:w-11/12">
                Enabling startups go lean, by minimizing operational costs and
                maximizing venture development.
              </p>
              {/* <div class="words words-1 text-left text-lg lg:text-xl inter font-semibold text-primary-caarya-medium">
                <span>Fluid Careers</span>
                <span>Hyper-Agile Remote Work</span>
                <span>The Cult of Neo-Generalism</span>
                <span>Fluid Careers</span>
              </div> */}
            </div>
          </div>
        </div>{" "}
        <div className="absolute bottom-0 right-0 left-0 w-full flex flex-col items-center justify-center mt-8 text-primary-caarya-medium">
          <Lottie animationData={arrowsAnimation} loop={true} />
          {/* <ChevronDownIcon className="w-6 h-6 opacity-25" />
          <ChevronDownIcon className="w-6 h-6 opacity-50" />
          <ChevronDownIcon className="w-6 h-6 opacity-75" />
          <ChevronDownIcon className="w-6 h-6 opacity-100" /> */}
        </div>
      </div>
    </div>
  );
}

export default StartupBanner;
