import React, { useState } from "react";
import NewsletterModal from "../NewsLetterModal";
import { subscribeNewsletter } from "config/axios/subscriber";
import { Transition } from "@headlessui/react";
import { Toaster, ToastIcon, toast, resolveValue } from "react-hot-toast";
import { storeEvent } from "App";

const TailwindToaster = () => {
  return (
    <Toaster position="top-right">
      {(t) => (
        <Transition
          appear
          show={t.visible}
          className="transform p-4 flex bg-white rounded shadow-lg"
          enter="transition-all duration-150"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <ToastIcon toast={t} />
          <p className="px-2">{resolveValue(t.message)}</p>
        </Transition>
      )}
    </Toaster>
  );
};

function AboutTCM() {
  const [showNewsletterModal, setShowNewsletterModal] = useState();
  const [email, setEmail] = useState("");
  const [adding, setAdding] = useState(false);

  const subscribeUser = async () => {
    if (email.trim().length > 5 && email.includes("@")) {
      setAdding(true);
      try {
        let body = {
          email: email,
        };

        const res = await subscribeNewsletter(body);
        console.log(res);
        storeEvent({
          category: "About",
          action: "subscribe",
          label: "Newsletter Subscription",
        });
        if (res?.status === 201) {
          setShowNewsletterModal({ isOpen: true, duplicate: false });
        }
      } catch (e) {
        if (e?.response?.status === 409)
          setShowNewsletterModal({ isOpen: true, duplicate: true });
        else toast.error("Something went wrong!");
      }
    } else {
      console.log("Error");
      toast.error("Email Invalid");
    }
    setEmail("");
    setAdding(false);
  };

  return (
    <div className="py-8 inter mb-20">
      <div class="w-full flex flex-col items-center max-w-7xl mx-auto bg-blue-light lg:rounded-[5em] p-5 lg:p-16">
        <div class="w-full items-center md:flex mx-auto lg:space-x-[120px] md:space-x-5 justify-center">
          <div class="hidden md:block rounded-xl">
            <img class="rounded-xl h-96" src="/assets/contact.png" alt="" />
          </div>
          <div class="flex flex-col  justify-center w-full md:w-1/2">
            <h1 class="text-center md:text-left poppins mt-8 text-2xl text-primary-caarya-medium font-medium md:text-3xl md:tracking-[3.2px]">
              The Caarya Monthly
            </h1>
            <div class="w-full md:w-1/2 highlight-img my-6 sm:hidden">
              <img
                class="w-full max-w-2xl  rounded-xl object-scale-down"
                src="/assets/contact.png"
                alt=""
              />
            </div>

            <p class="inter mt-4 text-primary-neutral-800 font-light tracking-[0.8px] leading-7">
              The good old weekend read is here! Sit back, relax and keep
              yourself updated with what the Caarya student community is upto.
              While at it, get a little inspired may be?
            </p>
            <NewsletterModal
              isOpen={showNewsletterModal?.isOpen ? true : false}
              closeModal={() =>
                setShowNewsletterModal({
                  ...showNewsletterModal,
                  isOpen: false,
                })
              }
              duplicate={showNewsletterModal?.duplicate}
            />
            <input
              placeholder="Email Address"
              type="email"
              value={email}
              className="mt-10 bg-white border border-primary-neutral-300 rounded-lg py-4 px-6 text-base font-light leading-6 text-black placeholder:text-primary-neutral-400 poppins"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <button
              type="button"
              onClick={subscribeUser}
              class="inter mb-10 mx-auto md:mx-0 hover:underline shadow-lg yellow-btn text-primary-neutral-800 font-semibold rounded-full my-8 max-w-max py-4 px-10 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            >
              {adding ? "Subscribing..." : "Subscribe to our Newsletter ->"}
            </button>
          </div>
        </div>
      </div>
      <TailwindToaster />
    </div>
  );
}

export default AboutTCM;
