import React from "react";

function POD() {
  return (
    <div className="bg-primary-dawn-1000">
      <div className="py-8 lg:py-20 px-4 max-w-7xl mx-auto flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-20 items-center justify-between">
        <img
          src="/assets/FutureVersion/startup/podImage.png"
          alt=""
          className="mx-auto max-w-[280px] lg:max-w-[396px] h-auto"
        />{" "}
        <div className="py-6 flex flex-col space-y-10 text-white">
          <div className="flex flex-row items-center space-x-6">
            <img
              src="/assets/FutureVersion/startup/pod.png"
              alt=""
              className="h-12 w-12 object-cover"
            />
            <div className="w-0.5 bg-white h-12" />
            <h1 className="poppins pod-text text-[18px] lg:text-2xl font-semibold lg:leading-9 lg:tracking-[2.4px]">
              POD Calendar
            </h1>
          </div>
          <h1 className="poppins text-2xl lg:text-[32px] font-light leading-9 tracking-[1.2px] lg:leading-[48px] lg:tracking-[3.2px] capitalize">
            Introducing POD Calendar
          </h1>
          <p className="inter text-sm lg:text-[18px] font-light leading-6 tracking-[0.7px] lg:leading-8 lg:tracking-[0.9px]">
            Caarya provides a tool to startups that helps them asses their
            current startup stage and come up with a POD calendar - a timeline
            for structured workforce hiring based on their delivery needs.
          </p>
        </div>
      </div>
    </div>
  );
}

export default POD;
