import "./style.css";
import { ChevronDownIcon } from "@heroicons/react/solid";

import Lottie from "lottie-react";
import arrowsAnimation from "./arrows.json";

function HomeBanner() {
  return (
    //Home Banner HTML
    <div className=" py-5 inter w-full mx-auto heroBgNew h-100v">
      <div class="container h-full items-center justify-center px-4 lg:px-20 mx-auto flex flex-row">
        <div class="flex flex-col w-full justify-center items-center text-center py-6 space-y-10">
          {/* <div className="w-full flex flex-col items-center space-y-2">
            <p className="text-white uppercase poppins font-medium tracking-[1.6px] lg:tracking-[1.8px] text-base lg:text-[20px] leading-5 lg:leading-[38px]">
              Introducing our vision for
            </p>
            <p className="text-primary-caarya-300 lowercase poppins tracking-[6.4px] lg:tracking-[12.8px] font-medium text-[32px] lg:text-[48px] leading-[38px]">
              caarya {window.innerWidth < 1024 && <br />}
              <span className="text-primary-peddle-300">2025</span>
            </p>
            <p className="text-primary-caarya-300 poppins py-1.5 tracking-[3.6px] lg:tracking-[4.2px] font-medium text-xs lg:text-sm leading-5">
              <span className="font-sanskrit tracking-[0px]">कार्य</span> (work){" "}
            </p>
          </div> */}
          <h1 className="text-center poppins text-white text-2xl leading-[38px] max-w-[1080px] mx-auto tracking-[2.4px] lg:text-[40px] lg:leading-[70px] lg:tracking-[4.4px] font-light capitalize">
            Fueling venture development through{" "}
            <span className="text-primary-caarya-300 font-normal break-words">
              workplace innovation
            </span>
          </h1>
          <p className="text-white inter text-base lg:text-[18px] font-light lg:leading-9 tracking-[1.6px] mx-auto max-w-3xl">
            We are enabling the paradigm shift towards industry 5.0 and the
            future of work by promoting{" "}
            <span className="text-primary-peddle-300 lg:text-white">
              human centric education
            </span>{" "}
            and{" "}
            <span className="text-primary-peddle-300 lg:text-white">
              delivery oriented talent pods.{" "}
            </span>
          </p>

          {/* <button className="getProfiledBtn-red text-white poppins px-8 py-4 text-base leading-5">
            Join Our Network &nbsp;&nbsp; &gt;
          </button> */}
          <div className="absolute bottom-0 lg:bottom-20  right-0 left-0 w-full flex flex-col items-center justify-center mt-8 text-white">
            {/* <ChevronDownIcon className="w-6 h-6 opacity-25" />
            <ChevronDownIcon className="w-6 h-6 opacity-50" />
            <ChevronDownIcon className="w-6 h-6 opacity-75" />
            <ChevronDownIcon className="w-6 h-6 opacity-100" /> */}

            <Lottie animationData={arrowsAnimation} loop={true} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeBanner;
