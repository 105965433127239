import { useEffect, useRef, useState } from "react";
import { getColleges } from "config/axios/colleges";
import { enrollStudent } from "config/axios/enroll";
import { ArrowRight } from "@phosphor-icons/react";
import { storeEvent } from "App";
let dv = {
  name: "",
  email: "",
  phone: "",
  college: {
    id: "",
    college_name: "",
    college_location: "",
  },
};
function Apply() {
  const [inputData, setInputData] = useState(dv);
  const [newCollege, setNewCollege] = useState(false);
  const [showCollegesDropdown, setShowCollegesDropdown] = useState(false);
  const [colleges, setColleges] = useState([]);
  const [filteredItems, setFilteredItems] = useState(colleges);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowCollegesDropdown(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: response } = await getColleges();
        setColleges(response.data);
        setFilteredItems(response.data);
      } catch (err) {
        console.log(err);
      }
    })();

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const enroll = async () => {
    try {
      const body = {
        ...inputData,
        fromForm: "Website",
      };
      if (newCollege) {
        body.college.isNew = true;
      }
      const { data: response } = await enrollStudent(body);
      if (response) {
        setInputData(dv);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        style={{
          background:
            window.innerWidth < 1024
              ? "url('/assets/mobileEnroll.png')"
              : "url('/assets/enroll.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundPositionY: 0,
        }}
        className="px-5 pt-20 pb-20 relative mt-10"
      >
        <div className="max-w-2xl mx-auto w-full px-2.5 flex flex-col items-center space-y-8">
          <div className="py-3 flex flex-col items-center space-y-2">
            <h1 className="text-white poppins text-2xl lg:text-3xl font-light">
              Join Our Founder Nexus!
            </h1>
            <p className="text-white capitalize inter text-sm lg:text-base font-light">
              To get access to our Future Talent Pool
            </p>
          </div>

          {/* <div className="flex flex-col space-y-6 w-full px-2">
            <div className="flex flex-col space-y-2 w-full">
              <label className="text-white font-poppins text-sm font-bold">
                Startup Name
              </label>
              <input
                required
                type="text"
                value={inputData.name}
                onChange={(e) =>
                  setInputData({ ...inputData, name: e.target.value })
                }
                className="bg-white rounded-lg py-3 px-4 text-primary-neutral-800 placeholder:text-primary-neutral-400 font-poppins text-sm font-light border-0"
              />
            </div>
            <div className="flex flex-col space-y-2 w-full">
              <label className="text-white font-poppins text-sm font-bold">
                Email
              </label>
              <input
                required
                type="email"
                value={inputData.email}
                onChange={(e) =>
                  setInputData({ ...inputData, email: e.target.value })
                }
                className="bg-white rounded-lg py-3 px-4 text-primary-neutral-800 placeholder:text-primary-neutral-400 font-poppins text-sm font-light border-0"
              />
            </div>
          </div> */}

          <a
            href="https://nexus.caarya.in/"
            target="__blank"
            onClick={() => {
              storeEvent({
                category: "Startups",
                action: "redirect",
                label: "Founder Nexus",
              });
            }}
            className="bg-black bg-opacity-80 rounded-full shadow-inner py-3 px-8 flex flex-row items-center space-x-2 justify-center text-white inter text-base font-semibold cursor-pointer"
          >
            <p className="">Register Your Startup</p>
            <ArrowRight size={16} />
          </a>
        </div>
      </div>
    </>
  );
}

export default Apply;
