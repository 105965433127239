import React, { useState, useEffect, useRef } from "react";
import {
  AnimatePresence,
  useScroll,
  motion,
  useMotionValueEvent,
  useAnimation,
  useSpring,
} from "framer-motion";
import ScrollContainer from "Components/Common/ScrollContainer";
import { Toaster, ToastIcon, toast, resolveValue } from "react-hot-toast";
import { subscribeNewsletter } from "config/axios/subscriber";

import { useInView } from "react-intersection-observer";
import { ScrollReveal } from "Components/Common/Helpers/ScrollReveal";
import NewsletterModal from "Components/FutureVersion/AboutusComponents/NewsLetterModal";
import { Transition } from "@headlessui/react";
import { storeEvent } from "App";

const TailwindToaster = () => {
  return (
    <Toaster position="top-right">
      {(t) => (
        <Transition
          appear
          show={t.visible}
          className="transform p-4 flex bg-white rounded shadow-lg"
          enter="transition-all duration-150"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <ToastIcon toast={t} />
          <p className="px-2">{resolveValue(t.message)}</p>
        </Transition>
      )}
    </Toaster>
  );
};
const fadeVariant = {
  fading: { opacity: 0 },
  faded: {
    opacity: 1,
    transition: { duration: 0.8, ease: "easeOut" },
  },
};
const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.3 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "30%" },
};

function Indulgence() {
  const [ref, inView] = useInView();
  const [ref2, inView2] = useInView();
  const { scrollYProgress, scrollY } = useScroll();
  const [scrollOffset, setScrollOffset] = useState(-150);
  const [fadeOffset, setFadeOffset] = useState();
  const [fadeOffsetDiff, setFadeOffsetDiff] = useState(0);
  const [maxHeight, setMaxHeight] = useState();
  const control_1 = useAnimation();
  const control_2 = useAnimation();
  const [scrollLegth, setScrollLength] = useState(0);
  const [showText, setShowText] = useState(false);
  const img1Ref = useRef(null);
  const img2Ref = useRef(null);
  const [email, setEmail] = useState("");
  const [adding, setAdding] = useState(false);
  const [showNewsletterModal, setShowNewsletterModal] = useState();

  const customThreshold = [...Array(100).keys()].map((x) => x / 100);

  // const img1Observer = useIntersectionObserver(img1Ref, customThreshold);
  // const img2Observer = useIntersectionObserver(img2Ref, customThreshold);

  // const img1ContainerTransform = useTransform(
  //     img1Observer.springValue,
  //     [0, 1],
  //     [5, 0]
  // );
  // const img2ContainerTransform = useTransform(
  //     img2Observer.springValue,
  //     [0, 1],
  //     [5, 0]
  // );
  // const img1transform = useTransform(
  //     img1Observer.springValue,
  //     [0, 1],
  //     [2, 1]
  // );
  // const img2transform = useTransform(
  //     img2Observer.springValue,
  //     [0, 1],
  //     [2, 1]
  // );
  useEffect(() => {
    console.log("inView", inView);
    if (inView && fadeOffset - fadeOffsetDiff + 200 >= maxHeight) {
      control_1.start("visible");
    }
    // else {
    //     control_2.start("hidden");

    //     control_1.start("hidden");
    // }
  }, [inView, scrollYProgress, fadeOffset, fadeOffsetDiff, maxHeight]);
  useEffect(() => {
    console.log("inView", inView2);
    if (inView2) {
      control_2.start("visible");
    }
    // else {
    //     control_2.start("hidden");

    //     control_1.start("hidden");
    // }
  }, [inView2]);
  useEffect(() => {
    if (window.innerWidth <= 1130 && window.innerWidth > 768) {
      setScrollLength("50px");
    } else if (window.innerWidth <= 768) {
      setScrollLength("20px");
    } else {
      setScrollLength("60px");
    }
  }, []);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-300);
    } else {
      setScrollOffset(-500);
    }
  }, []);
  const onScrollPage = () => {
    const offset = window?.scrollY;
    if (offset > 8 && !maxHeight) {
      var ele1 = document.getElementById("indulgence");
      if (ele1) {
        var offsetHeight = ele1.offsetHeight;
        setMaxHeight(offsetHeight - 1000);
      }
    }
    let ele = document.getElementById("indulgence");
    if (ele1) {
      let offsetDiv = ele.offsetTop;
      setFadeOffsetDiff(offsetDiv - 200);
      console.log("ppp", offset, offsetDiv);
    }
    console.log("ppp", offset);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScrollPage, true);
    return () => {
      window.removeEventListener("scroll", onScrollPage);
    };
  }, []);
  useEffect(() => {}, []);
  useMotionValueEvent(scrollY, "change", (latest) => {
    console.log(
      "Page scroll: ",
      latest,
      fadeOffset - fadeOffsetDiff,
      maxHeight
    );
    setFadeOffset(latest);
  });
  const subscribeUser = async () => {
    if (email.trim().length > 5 && email.includes("@")) {
      setAdding(true);
      try {
        let body = {
          email: email,
        };

        const res = await subscribeNewsletter(body);
        console.log(res);
        storeEvent({ category: "Home", action: "signUp", label: "" });
        if (res?.status === 201) {
          setShowNewsletterModal({ isOpen: true, duplicate: false });
        }
      } catch (e) {
        if (e?.response?.status === 409)
          setShowNewsletterModal({ isOpen: true, duplicate: true });
        else toast.error("Something went wrong!");
      }
    } else {
      console.log("Error");
      toast.error("Email Invalid");
    }
    setEmail("");
    setAdding(false);
  };
  return (
    <div id="indulgence" class="pt-6 pb-10 relative indulgence-bg">
      <NewsletterModal
        isOpen={showNewsletterModal?.isOpen ? true : false}
        closeModal={() =>
          setShowNewsletterModal({ ...showNewsletterModal, isOpen: false })
        }
        duplicate={showNewsletterModal?.duplicate}
      />{" "}
      <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div className="w-full mt-10 min-h-screen">
          <div className="w-full flex flex-col items-center justify-center space-y-2 lg:space-y-4">
            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="hidden"
                animate={control_2}
                className="mt-6 py-4 w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-xs font-light md:text-sm uppercase px-6 leading-normal text-primary-peddle-500 tracking-[1.2px] lg:tracking-[1.4px] text-center poppins pb-2 border-b-2 lg:border-b-4 w-max border-primary-caarya-medium">
                  Students For Startups
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_2}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-2xl font-light md:text-[32px] px-6 leading-9 tracking-[2.4px] lg:tracking-[3.2px] lg:leading-[48px] text-black text-center poppins w-max">
                  Career Fluidity For Democratized Venture Development
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_2}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-sm font-light md:text-base leading-normal text-primary-neutral-800 text-center inter max-w-4xl capitalize">
                  Introducing the{" "}
                  <span className="text-primary-caarya-medium">
                    future of education and work
                  </span>{" "}
                  through collaborative upskilling and purpose
                  driven career growth.
                </h4>
              </motion.div>
            </ScrollReveal>
          </div>
          <ul className="relative max-w-6xl lg:mx-5 xl:mx-auto lg:px-3 pt-8 md:px-8 xl:px-0">
            <motion.div
              style={{
                height:
                  fadeOffset - fadeOffsetDiff > maxHeight
                    ? maxHeight
                    : fadeOffset - fadeOffsetDiff < 0
                    ? 10
                    : fadeOffset - fadeOffsetDiff,
                backgroundColor: "#F3F2F2",
              }}
              className="w-0.5 rounded absolute top-5 left-1/2 bottom-0 hidden lg:block"
            />
            <IndulgenceCard
              item={{
                name: "Future-Ready Talent Dynamics",
                description:
                  "Caarya melds virtual work environments with ongoing upskilling, creating a talent ecosystem that balances technical prowess with human empathy. This approach prepares ventures for the evolving professional world, ensuring a workforce adept in both current and future challenges.",
                image:
                  window?.innerWidth < 1024
                    ? "/assets/FutureVersion/Home/Indulgence/mobile1.svg"
                    : "/assets/FutureVersion/Home/Indulgence/1.svg",
              }}
              bgColor="#f5fefe"
            />
            <IndulgenceCardReverse
              item={{
                name: "Collaborative Venture Evolution",
                description:
                  "Bridging educational outputs with industry needs, Caarya democratizes venture development. We emphasize remote collaboration and ethical workplace culture, fostering an environment ripe for creativity and critical thinking.",

                image:
                  window?.innerWidth < 1024
                    ? "/assets/FutureVersion/Home/Indulgence/mobile2.svg"
                    : "/assets/FutureVersion/Home/Indulgence/2.svg",
              }}
              bgColor="#eefdfe"
            />
            <IndulgenceCard
              item={{
                name: "Vision-Centric Work Culture",
                description:
                  "Focusing beyond traditional employment models, Caarya cultivates a vision-centric work culture. We leverage global trends and AI readiness to align personal goals with collective visions, fostering a workforce driven by passion and innovation.",

                image:
                  window?.innerWidth < 1024
                    ? "/assets/FutureVersion/Home/Indulgence/mobile3.svg"
                    : "/assets/FutureVersion/Home/Indulgence/3.svg",
              }}
              bgColor="#f5fefe"
            />
            <IndulgenceCardReverse
              item={{
                name: "Streamlining Ventures with Digital Efficiency",
                description:
                  "Caarya champions digital proficiency in venture operations, reducing costs through remote work strategies and cross-cultural training. This approach ensures an adaptable and efficient workforce, ready for the digital-first business era.",

                image:
                  window?.innerWidth < 1024
                    ? "/assets/FutureVersion/Home/Indulgence/mobile4.svg"
                    : "/assets/FutureVersion/Home/Indulgence/4.svg",
              }}
              bgColor="#eefdfe"
            />
          </ul>
          <div className="w-full flex flex-col items-center space-y-12">
            <motion.div
              ref={ref}
              variants={cardsVariant}
              initial="hidden"
              animate={control_1}
              className="w-full z-20 flex flex-row items-center justify-center"
            >
              <img
                src="/assets/FutureVersion/Home/Indulgence/bottom.png"
                alt=""
                className="object-contain mx-auto"
              />
            </motion.div>
            <motion.div
              ref={ref}
              variants={cardsVariant}
              initial="hidden"
              animate={control_1}
              className="w-full z-20 flex flex-row items-center justify-center"
            >
              <p className="text-black text-center poppins text-[18px] lg:text-2xl font-light leading-9 lg:leading-[48px]">
                Are you interested in knowing more about work, careers and
                education in 2025 ?
              </p>
            </motion.div>
            <motion.div
              ref={ref}
              variants={cardsVariant}
              initial="hidden"
              animate={control_1}
              className="w-full z-20 flex flex-col lg:flex-row space-y-6 lg:space-y-0 items-center justify-center lg:space-x-10"
            >
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
                value={email}
                className="border border-primary-neutral-300 w-full md:w-96 bg-white text-primary-neutral-800 poppins text-base leading-6 font-light  rounded-lg px-6 py-4"
                placeholder="Email Address"
              />
              <button
                type="button"
                onClick={subscribeUser}
                className="getProfiledBtn-red text-white poppins font-semibold text-sm leading-5"
              >
                {adding ? "Signing up..." : "Yes, Sign Me Up!"}
              </button>
            </motion.div>
            {/* <motion.div
              ref={ref}
              variants={cardsVariant}
              initial="hidden"
              animate={control_1}
              className="w-full z-20 pt-9 flex flex-row items-center justify-center space-x-10"
            >
              <button className="getProfiledBtn-light px-8 py-3 poppins font-semibold text-sm leading-5">
                No, I’m Not Interested
              </button>
            </motion.div> */}
            <TailwindToaster />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Indulgence;

function IndulgenceCard({ item, bgColor }) {
  const [ref, inView] = useInView();
  const { scrollYProgress } = useScroll();
  const control_1 = useAnimation();
  const control_2 = useAnimation();
  useEffect(() => {
    console.log("inView", inView);
    if (inView) {
      control_1.start("visible");
      setTimeout(() => {
        control_2.start("visible");
      }, 200);
    }
    // else {
    //     control_2.start("hidden");

    //     control_1.start("hidden");
    // }
  }, [inView, scrollYProgress]);

  return (
    <li className="max-w-5xl mx-auto flex flex-col-reverse lg:flex-row items-start lg:items-center justify-between w-full py-6 md:py-16 relative z-20">
      <motion.div
        ref={ref}
        variants={cardsVariant}
        initial="visible"
        animate={control_1}
        // style={{
        //   background: window.innerWidth < 768 ? bgColor : "transparent",
        // }}
        className="lg:w-1/2 flex flex-col items-center text-center justify-center space-y-2 pb-8 pt-8 lg:pt-2.5  z-20 lg:bg-transparent lg:pr-8"
      >
        <h1 class="text-lg lg:text-2xl font-light leading-7 lg:leading-9 text-secondary-error-500 poppins lg:tracking-[1.2px]">
          {item?.name}
        </h1>
        <p class="text-sm inter mb-8 leading-5 lg:tracking-[0.7px] text-primary-neutral-800 lg:w-11/12 font-light">
          {item?.description}
        </p>
      </motion.div>

      <motion.div
        variants={cardsVariant}
        initial="visible"
        animate={control_2}
        className="float-right lg:w-1/2 mx-auto  md:mt-0  z-20 lg:bg-transparent pb-0"
      >
        <img src={item?.image} alt="" className="object-contain mx-auto" />
      </motion.div>
    </li>
  );
}

function IndulgenceCardReverse({ item, bgColor }) {
  const [ref, inView] = useInView();
  const { scrollYProgress } = useScroll();
  const control_1 = useAnimation();
  const control_2 = useAnimation();
  useEffect(() => {
    console.log("inView", inView);
    if (inView) {
      control_1.start("visible");
      setTimeout(() => {
        control_2.start("visible");
      }, 200);
    }
    // else {
    //     control_2.start("hidden");

    //     control_1.start("hidden");
    // }
  }, [inView, scrollYProgress]);

  return (
    <li className="max-w-5xl mx-auto flex flex-col-reverse lg:flex-row-reverse items-start lg:items-center justify-between w-full py-6 md:py-16 relative z-20">
      {/* <Fade direction="left" delay="250"> */}
      <motion.div
        ref={ref}
        variants={cardsVariant}
        initial="visible"
        // style={{
        //   background: window.innerWidth < 768 ? bgColor : "transparent",
        // }}
        animate={control_1}
        className="lg:w-1/2 flex flex-col items-center text-center justify-center space-y-4 pb-8 pt-8 lg:pt-2.5  z-20 lg:bg-transparent lg:pl-8"
      >
        <h1 class="text-lg lg:text-2xl font-light leading-7 lg:leading-9 text-secondary-error-500 poppins lg:tracking-[1.2px]">
          {item?.name}
        </h1>
        <p class="text-sm inter mb-8 leading-5 lg:tracking-[0.7px] text-primary-neutral-800 lg:w-11/12 font-light">
          {item?.description}
        </p>
      </motion.div>
      {/* </Fade>
                                        <Fade direction="right" delay="250"> */}
      <motion.div
        variants={cardsVariant}
        initial="visible"
        animate={control_2}
        className="float-right lg:w-1/2 mx-auto  md:mt-0 z-20 lg:bg-transparent"
      >
        <img src={item?.image} alt="" className="object-contain mx-auto" />
      </motion.div>
      {/* </Fade> */}
    </li>
  );
}

const useIntersectionObserver = (
  target,
  customThreshold = [0],
  customRoot = null
) => {
  const [inView, setInView] = useState(false);
  const springPhysics = { damping: 400, friction: 100 };
  const springValue = useSpring(0, springPhysics);

  useEffect(() => {
    const observerCallback = ([entry]) => {
      if (entry.isIntersecting) {
        setInView(true);
        springValue.set(entry.intersectionRatio);
      } else {
        setInView(false);
      }
    };

    const observerOptions = {
      root: customRoot,
      rootMargin: "0px 0px 0px 0px",
      threshold: customThreshold,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    observer.observe(target.current);

    return () => observer.disconnect();
  }, [target, customThreshold, customRoot, springValue]);

  return { inView, springValue };
};
