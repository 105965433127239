import {
  AnimatePresence,
  useScroll,
  motion,
  useAnimation,
} from "framer-motion";
import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { ScrollReveal } from "Components/Common/Helpers/ScrollReveal";

const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "60%" },
};

const hexagonVariant_2 = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "10%" },
};

function Hexagons() {
  const [ref, inView] = useInView({ triggerOnce: true });
  const [scrollOffset, setScrollOffset] = useState(-150);
  const [maxHeight, setMaxHeight] = useState();
  const { scrollYProgress, scrollY } = useScroll();
  const [ref3, inView3] = useInView();
  const control = useAnimation();
  const control_1 = useAnimation();
  const control_2 = useAnimation();
  const control_3 = useAnimation();
  const control_4 = useAnimation();
  const control_h1 = useAnimation();

  const onScrollPage = () => {
    const offset = window?.scrollY;
    if (offset > 8 && !maxHeight) {
      var ele = document.getElementById("programsList");
      if (ele) {
        var offsetHeight = ele.offsetHeight;
        setMaxHeight(offsetHeight);
      }
    }
  };

  useEffect(() => {
    console.log("inView", inView3);
    if (inView3) {
      control_h1.start("visible");
    }
  }, [inView3]);
  useEffect(() => {
    window.addEventListener("scroll", onScrollPage, true);
    return () => {
      window.removeEventListener("scroll", onScrollPage);
    };
  }, []);

  useEffect(() => {
    if (inView) {
      control.start("visible");
      setTimeout(() => {
        control_1.start("visible");
      }, 200);
      setTimeout(() => {
        control_2.start("visible");
      }, 400);
      setTimeout(() => {
        control_3.start("visible");
      }, 600);
      setTimeout(() => {
        control_4.start("visible");
      }, 800);
    } else {
      control_4.start("hidden");
      setTimeout(() => {
        control_3.start("hidden");
      }, 200);
      setTimeout(() => {
        control_2.start("hidden");
      }, 400);
      setTimeout(() => {
        control_1.start("hidden");
      }, 600);
      setTimeout(() => {
        control.start("hidden");
      }, 800);
    }
  }, [inView, control, scrollYProgress]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-300);
    } else {
      setScrollOffset(-500);
    }
  }, []);
  return (
    <>
      <AnimatePresence>
        <div className="py-10 inter w-full mx-auto ">
          <div class="container px-4 md:px-20 mx-auto flex flex-col items-center">
            <div className="w-full flex flex-col items-center justify-center space-y-4">
              <ScrollReveal offset={scrollOffset}>
                <motion.div
                  ref={ref3}
                  variants={cardsVariant}
                  initial="hidden"
                  animate={control_h1}
                  className="mt-6 py-4 w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
                >
                  <h4 class="text-xs font-light md:text-sm uppercase px-6 leading-normal text-primary-neutral-800 text-center poppins pb-2 border-b-2 lg:border-b-4 w-max border-primary-caarya-medium">
                    Become a Member
                  </h4>
                </motion.div>
              </ScrollReveal>

              <ScrollReveal offset={scrollOffset}>
                <motion.div
                  ref={ref3}
                  variants={cardsVariant}
                  initial="visible"
                  animate={control_h1}
                  className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
                >
                  <h4 class="text-lg font-light md:text-[32px] px-6 leading-9 lg:leading-[48px] text-black text-center poppins w-max capitalize">
                    Fix Your Delivery and Talent Needs by Being a Part of Our
                    Founder Nexus
                  </h4>
                </motion.div>
              </ScrollReveal>

              <ScrollReveal offset={scrollOffset}>
                <motion.div
                  ref={ref3}
                  variants={cardsVariant}
                  initial="visible"
                  animate={control_h1}
                  className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
                >
                  <h4 class="text-sm font-light md:text-base leading-normal text-primary-neutral-500 text-center inter max-w-4xl capitalize">
                    Build Adaptable & Resilient identities through our knowledge
                    development areas and gain the adaptation advantage{" "}
                  </h4>
                </motion.div>
              </ScrollReveal>
            </div>
            <div class="flex flex-col w-full justify-center items-center text-center lg:max-w-3xl mx-auto mt-16">
              <motion.div
                ref={ref}
                variants={hexagonVariant_2}
                initial="hidden"
                animate={control_h1}
                className="flex flex-row items-center justify-center "
              >
                <img
                  src="/assets/FutureVersion/Home/adapt.png"
                  alt=""
                  className=" mx-auto"
                />
              </motion.div>
            </div>
            <button className="my-10 getProfiledBtn-red inter font-semibold text-sm leading-5">
              Get Started!
            </button>
          </div>
        </div>
      </AnimatePresence>
    </>
  );
}

export default Hexagons;
