import { ScrollReveal } from "Components/Common/Helpers/ScrollReveal";
import { useScroll, motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "60%" },
};

function VirtualOffice() {
  const [scrollOffset, setScrollOffset] = useState(-150);
  const [ref2, inView2] = useInView();
  const control_h = useAnimation();
  useEffect(() => {
    console.log("inView", inView2);
    if (inView2) {
      control_h.start("visible");
    }
  }, [inView2]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-300);
    } else {
      setScrollOffset(-500);
    }
  }, []);
  return (
    <>
      <div class="py-14 relative">
        <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="w-full flex flex-col items-center justify-center space-y-2 lg:space-y-4">
            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="hidden"
                animate={control_h}
                className="mt-6 py-4 w-full max-w-[100vw] z-20 flex flex-row items-center justify-center px-4"
              >
                <h4 class="text-xs font-light md:text-sm uppercase px-6 leading-normal text-primary-peddle-500 tracking-[1.2px] lg:tracking-[1.4px] text-center poppins pb-2 border-b-2 lg:border-b-4 w-max border-primary-caarya-medium">
                  Navigating Global evolution through personalized guidance
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-2xl font-light md:text-[32px] px-6 leading-9 tracking-[2.4px] lg:tracking-[3.2px] lg:leading-[48px] text-black text-center poppins w-max">
                  Introducing Virtual Office Spaces for Students
                </h4>
              </motion.div>
            </ScrollReveal>

            <ScrollReveal offset={scrollOffset}>
              <motion.div
                ref={ref2}
                variants={cardsVariant}
                initial="visible"
                animate={control_h}
                className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center"
              >
                <h4 class="text-sm font-light md:text-base leading-normal text-primary-neutral-500 text-center inter max-w-4xl">
                  Master collaboration through CREWS connecting growth
                  centers across India
                </h4>
              </motion.div>
            </ScrollReveal>
          </div>
          <div className="flex flex-col items-center space-y-10 lg:space-y-6 max-w-4xl mx-auto mt-10">
            <img
              src="/assets/FutureVersion/students/hq.png"
              alt=""
              className=""
            />
            <p className="text-primary-neutral-500 inter text-sm lg:text-base font-light max-w-[800px] mt-6 mx-auto leading-6 lg:leading-8 tracking-[0.7px] text-center lg:tracking-[0.8px]">
              Our Caarya Remote Experiential Workplaces (CREWs) are designed
              to facilitate students adaptation to remote work and
              collaboration. They provide opportunities for engagement with both
              peers and mentors across various caarya growth centres, thereby
              promoting peer learning and professional growth.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default VirtualOffice;
