import { ScrollReveal } from "Components/Common/Helpers/ScrollReveal";
import { useScroll, motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const cardsVariant = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
    translateY: 0,
  },
  hidden: { opacity: 0, translateY: "60%" },
};
function AboutLeadershipNote() {
  const [scrollOffset, setScrollOffset] = useState(-150);
  const [ref2, inView2] = useInView();
  const control_h = useAnimation();
  useEffect(() => {
    console.log("inView", inView2);
    if (inView2) {
      control_h.start("visible");
    }
  }, [inView2]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-300);
    } else {
      setScrollOffset(-500);
    }
  }, []);
  return (
    <div class="flex flex-wrap flex-col content-center text-center md:pb-0">
      <div class="w-full sm:w-7/12">
        <ScrollReveal offset={scrollOffset}>
          <motion.div
            ref={ref2}
            variants={cardsVariant}
            initial="hidden"
            animate={control_h}
          >
            {" "}
            <h1 class="my-4 mt-12 text-xl lg:text-[32px] font-medium text-center text-grey-800 poppins leading-[48px] tracking-[3.2px]">
              A Note from the Leadership
            </h1>
          </motion.div>
        </ScrollReveal>

        <div class="w-full mb-4">
          <div class="h-1 mx-auto red-gradient w-64 my-0 py-0 rounded-t"></div>
        </div>
        <p class="inter mb-10 mt-6 max-w-[800px] text-justify mx-auto p-4 px-4 md:px-0 text-primary-neutral-800 font-light leading-7 tracking-[0.8px]">
          Young minds with a thirst for learning brimming with energy and
          innovation are the flag bearers of technology for social change.
          Caarya is proud to be the initiative that enables a student to
          understand their true potential and tap into it. <br />
          <br />
          The current Indian startup ecosystem includes engineers, designers,
          visual artists and content developers. A rapidly changing economy
          however warrants for adaptive change. To enable this adaptation we
          need an inflow of transferable skills and right networking. It will
          add to the skillsets of this talent pool and help them transform into
          thought leaders, master strategists and innovators - the building
          blocks of the new Indian startup ecosystem. <br />
          <br />
          At Caarya, we have a hive of learners, ideators and working
          professionals on streamlined channels for your interests. Networking
          in this system while you are a student gives you opportunities to tap
          into and validate your potential. We aim to supplement knowledge,
          skills, values, beliefs, habits and attitudes with learning
          experiences.
          <br />
          <br />
          Join us to be a part of the Indian student ecosystem that is needed to
          drive the Indian startup ecosystem.
        </p>
      </div>
    </div>
  );
}

export default AboutLeadershipNote;
