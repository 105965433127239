import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { BiRocket } from "react-icons/bi";
import { FaGraduationCap } from "react-icons/fa";
import HoverComponent from "../Navbar/HoverComponent";
import DropdownComponent from "../Navbar/DropdownComponent";
import LinkComponent from "../Navbar/LinkComponent";
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
const mediaNavigator = [
  {
    path: "/highlightreel",
    name: "Highlight Reel",
  },
  {
    path: "/thecuriouspro",
    name: "The Curious Pro",
  },
];
const exploreNavigator = [
  {
    path: "/explorecaarya",
    name: "Explore Caarya",
  },
  {
    path: "/aboutcaarya",
    name: "About Us",
  },
  // {
  //     path: "/facesofcaarya",
  //     name: "Faces of Caarya",
  // },
  // {
  //     path: "/studentPage",
  //     name: "Kickstart Meaningful Careers",
  // },
];
const offeringsNavigator = [
  {
    path: "/offerings/Career Kickstarter School",
    name: "Career Kickstarter School",
    alter: "/offerings",
  },
  {
    path: "/offerings/caaryacommunities",
    name: "Caarya Communities",
  },
  {
    path: "/offerings/caaryaworkstudy",
    name: "Fusion Work Study",
  },
  {
    path: "/offerings/Industry Safari",
    name: "Industry Safari",
  },
];
const programsNavigator = [
  {
    path: "/offerings/workScholarshipProgram",
    name: "Work Scholarship Program",
    alter: "/offerings",
  },
  {
    path: "/offerings/fusionindustrialcontacts",
    name: "Fusion Industrial Contracts",
  },
  {
    path: "/offerings/youngleaderprogram",
    name: "Young Leader’s Accelerator Program",
  },
  {
    path: "/offerings/Industry Safari",
    name: "Industry Safari",
  },
];

const navBar = {
  light: {
    backgroundColor: "white",
    transition: { duration: 1, ease: "easeInOut", property: "all" },
  },
  dark: {
    backgroundColor: "transparent",
    transition: { duration: 1, ease: "easeInOut", property: "all" },
  },
};
function FHomeBannerNav() {
  const location = useLocation();
  const active = location.pathname;
  const [isMobile, setIsMobile] = useState(false);
  const [dark, setDark] = useState(true);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest > 500 && dark) {
      setDark(false);
    } else if (latest < 500 && !dark && location?.pathname == "/") {
      setDark(true);
    }
  });
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const navToggle = () => {
    console.log("Toggle");
    var btn = document.getElementById("menuBtn");
    btn.classList.toggle("open");
    var menu = document.getElementById("mobile-nav-menu");
    menu.classList.toggle("visible");
  };

  useEffect(() => {
    if (location?.pathname !== "/") setDark(false);
  }, [location?.pathname]);

  return (
    //Home Banner HTML
    <AnimatePresence>
      <motion.div
        variants={navBar}
        initial={dark ? navBar.dark : navBar.light}
        animate={dark ? navBar.dark : navBar.light}
        className={`w-full fixed z-40 `}
      >
        {console.log(dark)}
        <div className="banner-header">
          <nav class="relative flex flex-wrap items-center justify-between py-4 poppins">
            <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
              <div class="w-full relative flex justify-between md:w-auto h-8 md:static md:block md:justify-start">
                <div className="flex flex-row items-center space-x-6">
                  <Link
                    class="leading-relaxed inline-block mr-4 whitespace-nowrap"
                    to="/"
                  >
                    <img
                      src={
                        dark
                          ? "/assets/caarya_banner_light.svg"
                          : "/assets/caarya_banner.svg"
                      }
                      alt="howdy"
                      className="h-8 banner-logo"
                    />
                  </Link>
                  <Link
                    to="/studentPage"
                    className={`hidden lg:flex flex-row items-center space-x-2 poppins text-sm leading-5 ${
                      dark ? "text-white" : "text-gray-800"
                    }`}
                  >
                    <FaGraduationCap size={16} />{" "}
                    <p className="hidden lg:block">For Students</p>
                  </Link>
                  <Link
                    to="/startup"
                    className={`hidden lg:flex flex-row items-center space-x-2 poppins text-sm leading-5 ${
                      dark ? "text-white" : "text-gray-800"
                    }`}
                  >
                    <BiRocket size={16} />
                    <p className="hidden lg:block">For Startups</p>
                  </Link>
                </div>

                <button
                  id="menuBtn"
                  class="hamburger block md:hidden focus:outline-none mr-2 z-40"
                  type="button"
                  onClick={navToggle}
                >
                  <span
                    class={`hamburger__top-bun ${dark ? "dark" : ""}`}
                  ></span>
                  <span
                    class={`hamburger__bottom-bun ${dark ? "dark" : ""}`}
                  ></span>
                </button>
                <div
                  class="mobile-nav-menu overflow-scroll"
                  id="mobile-nav-menu"
                >
                  <ul class="flex flex-col list-none mx-auto items-center justify-center h-full">
                    <Link
                      to="/studentPage"
                      className={`px-3 py-6 md:py-1 flex flex-row items-center space-x-4 poppins text-xl md:text-sm leading-5 text-black`}
                    >
                      <FaGraduationCap size={20} />{" "}
                      <p className="block">For Students</p>
                    </Link>
                    <Link
                      to="/startup"
                      className={`px-3 py-6 md:py-1 flex flex-row items-center space-x-4 poppins text-xl md:text-sm leading-5 text-black`}
                    >
                      <BiRocket size={20} />
                      <p className="block">For Startups</p>
                    </Link>
                    <div className="w-4/6 my-6 h-[1px] bg-black" />
                    {/* <DropdownComponent
                      dark={dark}
                      title="Explore"
                      navigator={exploreNavigator}
                    /> */}
                    {/* <DropdownComponent
                                        title="Programs"
                                        navigator={programsNavigator}
                                    /> */}
                    <LinkComponent
                      dark={dark}
                      title="About Us"
                      link="/aboutcaarya"
                    />{" "}
                    <LinkComponent
                      dark={dark}
                      title="Media"
                      link="/highlightreel"
                    />{" "}
                    {/* <DropdownComponent
                      dark={dark}
                      title="Media"
                      navigator={mediaNavigator}
                    /> */}
                    {/* <LinkComponent
                      dark={dark}
                      title="Contact Us"
                      link="/joincaarya"
                    />{" "} */}
                    {/* <LinkComponent
                                            dark={dark}
                                            title="BYOI"
                                            link="/buildyourownidentity"
                                        /> */}
                  </ul>
                </div>
              </div>
              {!isMobile && (
                <div
                  class="md:flex hidden md:flex-grow items-center"
                  id="home-banner-navbar"
                >
                  <ul class="flex flex-col md:flex-row list-none ml-auto">
                    {/* <HoverComponent
                      dark={dark}
                      title="Explore"
                      navigator={exploreNavigator}
                    /> */}
                    {/* <HoverComponent
                                        title="Programs"
                                        navigator={programsNavigator}
                                    /> */}
                    {/* <HoverComponent
                      dark={dark}
                      title="Media"
                      navigator={mediaNavigator}
                    />
                    <LinkComponent
                      dark={dark}
                      title="Contact Us"
                      link="/joincaarya"
                    />{" "} */}
                    <LinkComponent
                      dark={dark}
                      title="About Us"
                      link="/aboutcaarya"
                    />{" "}
                    <LinkComponent
                      dark={dark}
                      title="Media"
                      link="/highlightreel"
                    />{" "}
                    {/* <LinkComponent
                                            dark={dark}
                                            title="BYOI"
                                            link="/buildyourownidentity"
                                        /> */}
                  </ul>
                </div>
              )}
            </div>
          </nav>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default FHomeBannerNav;
