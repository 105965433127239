import Tilt from "react-parallax-tilt";
import "./style.css";

export default function Polaroid({ src }) {
  return (
    <div class="item">
      <Tilt glareEnable={true}>
        <div class="polaroid items-center">
          {src ? <img src={src} /> : <p class="leading-relaxed mb-3 w-96 h-96 animate-pulse bg-gray-200"></p>}
        </div>
      </Tilt>
    </div>
  );
}
