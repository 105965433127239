import React from 'react';

const Input = ({ placeholder, value, onChange, type }) => {
	if (type === 'textarea')
		return (
			<textarea
				className="p-2 w-full bg-gray-100 rounded-lg border outline-none focus:ring-transparent-none mt-1 h-36 resize-y"
				placeholder={placeholder}
				value={value}
				onChange={onChange}
			/>
		);
	return (
		<input
			className="p-2 w-full bg-gray-100 rounded-lg border outline-none focus:ring-transparent-none mt-1 "
			type={type}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
		/>
	);
};

export default Input;
