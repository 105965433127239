import CaaryaverseCover from "Components/FutureExploreComponents/ExploreCover";
import Footer from "Components/Common/Footer";
import HomeBannerNav from "Components/Common/FHomeBannerNav";
import StudentPathway from "Components/FutureExploreComponents/StudentPathway";
import FAQ from "Components/FutureExploreComponents/FAQ";
import VirtualOfficeSpace from "Components/FutureExploreComponents/VirtualOfficeSpace";
import CGC from "Components/FutureExploreComponents/CGC";
import Testimonials from "Components/FutureExploreComponents/Testimonial";
import Events from "Components/FutureExploreComponents/Events";
import Newsletter from "Components/FutureExploreComponents/Newsletters";
import StudentProgram from "Components/FutureExploreComponents/StudentPrograms";
import { useEffect } from "react";

function FCaaryaverse() {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <HomeBannerNav backgroundColor={"red-plain"} />
      <CaaryaverseCover />
      <StudentPathway />
      <StudentProgram />
      <FAQ />
      <VirtualOfficeSpace />
      <Testimonials /> <CGC />
      <Events />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default FCaaryaverse;
