import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

export default function Loader() {
  const route = useRouteMatch();
  const [loaded, setLoaded] = useState(false);
  const [loadedLoader, setLoadedLoader] = useState(true);
  const [loadedHide, setLoadedHide] = useState(false);

  useEffect(() => {
    if (!loadedLoader) {
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    }
  }, [loadedLoader]);

  useEffect(() => {
    setTimeout(() => {
      setLoadedLoader(false);
      localStorage.setItem(route?.path, true);
    }, 750);
  }, [loadedLoader]);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoadedHide(true);
      }, 500);
    }
  }, [loaded]);

  return (
    <>
      {!loadedHide && (
        <motion.div
          initial={{
            opacity: 1,
            // y: "0",
            transition: {
              duration: 0.3,
            },
          }}
          animate={
            !loaded
              ? {
                  opacity: 1,
                  // y: "0",
                  transition: {
                    duration: 0.5,
                  },
                }
              : {
                  opacity: 0,
                  // y: "0",
                  transition: {
                    duration: 0.5,
                  },
                }
          }
          className="h-screen bg-white lg:hidden fixed top-0 right-0 left-0 z-[1000] bottom-0 w-full flex flex-col"
        >
          <div className="h-screen w-full loaderBg flex flex-col">
            <div className="h-[95vh] w-full flex flex-col items-center justify-center">
              <motion.img
                initial={{
                  opacity: 0.4,
                  // y: "0",
                  transition: {
                    duration: 0.3,
                  },
                }}
                animate={
                  loadedLoader
                    ? {
                        opacity: 0.8,
                        // y: "0",
                        transition: {
                          duration: 0.3,
                        },
                      }
                    : {
                        opacity: 0.4,
                        // y: "0",
                        transition: {
                          duration: 0.3,
                        },
                      }
                }
                transition={{ duration: 0.3 }}
                src="/assets/loader.svg"
                className="w-[100px] scale-110 h-auto"
              />
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
}
