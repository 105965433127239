function BlogContest() {
  return (
    <div className="py-8 inter">
      <section class="container w-full mx-auto m-8">
        <div class="w-full flex flex-col items-center max-w-7xl mx-auto bg-blue-light container-rounded py-16 px-10">
          <h1 class="poppins w-full my-2 opacity-75 text-md font-bold leading-tight text-center aqua-400">
            UP NEXT ON THE CURIOUS PRO
          </h1>
          <h1 class="poppins w-full my-2 text-3xl font-bold leading-tight text-center red-400">
            A SILVER LINING
          </h1>
          <div class="w-full mb-4 mt-4">
            <div class="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div class="mb-16 pt-0">
            <p class="inter my-2 md:w-4/5 mx-auto leading-tight text-center text-gray-800">
              “There is one more thing, I want you to learn some leadership
              skills, so I am putting you under a mentorship of this new guy
              called Vivek. Work with him for some months. He has solid
              experience. See what you can learn. In the Creator Economy we can
              either be ignorant or be curious and learn. More you learn and
              research, the better it is for the world and yourself. I am not
              giving you any Gyan nor I am telling you that your work is
              substandard. I just want you to be better.”{" "}
            </p>
          </div>
          {/* <button class="inter mx-auto lg:mx-0 hover:underline text-grey-800 font-bold rounded-full my-3 md:my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out yellow-gradient">
          Send your Entry ->
        </button> */}
        </div>
      </section>
    </div>
  );
}

export default BlogContest;
