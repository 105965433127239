import "./style.css";

const list = [
    { image: "/assets/FutureVersion/Explore/1.png", name: "Explore" },
    { image: "/assets/FutureVersion/Explore/2.png", name: "Align" },
    { image: "/assets/FutureVersion/Explore/3.png", name: "Activate" },
];

function Events() {
    return (
        //Home Banner HTML
        <div className="py-8 inter w-full mx-auto">
            <div class="pt-6 pb-10 md:pb-0">
                <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                    <div class="flex flex-col text-center w-full justify-center items-center">
                        <h1 class="my-4 text-center mt-0 md:mt-32 text-3xl md:text-4xl font-bold leading-tight text-gray-800 poppins">
                            Events
                        </h1>
                        <p class="leading-normal text-xl md:text-xl mb-8 text-gray-800 inter md:w-11/12">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                        </p>
                        <div className="flex flex-row items-stretch justify-center flex-wrap">
                            {list?.map((item, index) => {
                                return (
                                    <div className="m-5 event-card flex flex-col items-start max-w-xs border border-gray-200">
                                        <div className="bg-gray-100 w-full rounded-lg h-44" />
                                        <div className="flex flex-col items-start p-4 text-left">
                                            <h1 class="my-3 text-center md:text-left font-bold leading-tight text-gray-800 poppins">
                                                Event Name
                                            </h1>

                                            <p class="leading-normal text-sm line-clamp-5 text-gray-800 inter">
                                                Lorem Ipsum is simply dummy text
                                                of the printing and typesetting
                                                industry. Lorem Ipsum has been
                                                the industry's standard dummy
                                                text ever since the 1500s, when
                                                an unknown printer took a galley
                                                of type and scrambled it
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Events;
