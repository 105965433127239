import "./style.css";

function VirtualOfficeSpace() {
    return (
        //Home Banner HTML
        <div className="inter w-full mx-auto">
            <div class="pt-4 pb-10">
                <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                    <div class="flex flex-col text-center w-full justify-center items-center">
                        <h1 class="my-4 text-center mt-0 md:mt-32 text-3xl md:text-4xl font-bold leading-tight text-gray-800 poppins">
                            Virtual Office Space
                        </h1>
                        <p class="leading-normal text-xl md:text-xl mb-8 text-gray-800 inter md:w-11/12">
                            Through our virtual spaces we teach students office
                            etiquettes. Here we focus on giving students an
                            office-like environment where they build connections
                            and bond with their people in{" "}
                            <span className="red-400 font-bold">CAARYA</span>
                        </p>
                        <div className="bg-gray-100 w-full rounded h-50v" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VirtualOfficeSpace;
