import "./style.css";

function CGC() {
    return (
        //Home Banner HTML
        <div className="inter w-full mx-auto">
            <div class="pt-4 pb-10">
                <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                    <div class="flex flex-col text-center w-full justify-center items-center">
                        <h1 class="my-4 text-center mt-0 md:mt-32 text-3xl md:text-4xl font-bold leading-tight text-gray-800 poppins">
                            CGC - Career Growth Centers
                        </h1>
                        <p class="leading-normal text-xl md:text-xl mb-8 text-gray-800 inter md:w-11/12">
                            Our Career Growth Centers - Find your nearest
                            excellence center and get to experience our events
                            while you kickstart your professional career through
                            our work study programs.{" "}
                        </p>
                        <div className="bg-gray-100 w-full rounded h-50v" />
                        <div className="cgc-button inter cursor-pointer mt-8 inter py-2.5 px-5">
                            Check CGC Website
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CGC;
