import "./style.css";

const list = [
    { image: "/assets/FutureVersion/Explore/1.png", name: "Explore" },
    { image: "/assets/FutureVersion/Explore/2.png", name: "Align" },
    { image: "/assets/FutureVersion/Explore/3.png", name: "Activate" },
    { image: "/assets/FutureVersion/Explore/4.png", name: "Enhance" },
    { image: "/assets/FutureVersion/Explore/5.png", name: "Advance" },
];

function StudentPathway() {
    return (
        //Home Banner HTML
        <div className="py-8 inter w-full mx-auto">
            <div class="pt-6 pb-10 md:pb-0">
                <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                    <div class="flex flex-col text-center w-full justify-center items-center">
                        <h1 class="my-4 text-center mt-0 md:mt-32 text-3xl md:text-4xl font-bold leading-tight text-gray-800 poppins">
                            Our Student Pathways
                        </h1>
                        <p class="leading-normal text-xl md:text-xl mb-8 text-gray-800 inter md:w-11/12">
                            Our student pathways ensure that we give an
                            office-like environment experience to students and
                            help them leverage their transferable skills
                        </p>
                        <div className="flex flex-row items-stretch justify-center flex-wrap">
                            {list?.map((item, index) => {
                                return (
                                    <div className="m-5 flex flex-col items-center space-y-1.5">
                                        <img
                                            src={item?.image}
                                            alt=""
                                            className="w-48 mb-3 h-48 object-cover rounded-full bg-red-light"
                                        />
                                        <div className="text-base text-white inter h-9 w-9 bg-red-500 flex flex-row items-center justify-center rounded-full">
                                            0{index + 1}{" "}
                                        </div>
                                        <div className="text-xl red-500 text-center font-bold inter">
                                            {item?.name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentPathway;
