import { storeEvent } from "App";

function ReelFollowUs({ bgColor = "gradient" }) {
  return (
    <div className="py-8 inter">
      <section class="container w-full mx-auto m-8">
        <div class="w-full flex flex-col items-center max-w-7xl mx-auto bg-blue-light lg:rounded-[5em] p-16">
          <h1 class="poppins w-full mt-10 mb-3 text-3xl  font-bold leading-tight text-center aqua-400">
            Follow The <span className="red-400">Caarya</span> Network
          </h1>
          <div class="w-full mb-4">
            <div class="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
          </div>

          <div className="flex flex-row justify-center pb-16 pt-6">
            <a
              onClick={() => {
                storeEvent({
                  category: "Media",
                  action: "redirect",
                  label: "Instagram",
                });
              }}
              className="p-3"
              href="https://www.instagram.com/caarya.in/?igshid=MDM4ZDc5MmU%3D"
              target="blank"
            >
              <img
                src="./assets/instaBlack.png"
                className="w-12 cursor-pointer hover:opacity-75"
              />
            </a>
            <a
              onClick={() => {
                storeEvent({
                  category: "Media",
                  action: "redirect",
                  label: "LinkedIn",
                });
              }}
              className="p-3"
              href="https://www.linkedin.com/company/caarya/"
              target="blank"
            >
              <img
                src="./assets/linkedinBlack.png"
                className="w-12 cursor-pointer hover:opacity-75"
              />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ReelFollowUs;
