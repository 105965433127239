import { motion, useScroll, useTransform } from "framer-motion";
import { useElementViewportPosition } from "./useElementViewportPosition";
import React, { useRef } from "react";

export const ScrollReveal = ({
  offset = 0,
  distance = 60,
  children,
  startOpacity = 0,
  rotateStart = 0,
  distanceX = 0,
}) => {
  const ref = useRef(null);
  const { position } = useElementViewportPosition(ref, offset);
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, position, [startOpacity, 1], {
    type: "spring",
    stiffness: 400,
    damping: 50,
  });
  const y = useTransform(scrollYProgress, position, [distance, 0], {
    type: "spring",
    stiffness: 400,
    damping: 50,
  });
  const x = useTransform(scrollYProgress, position, [distanceX, 0], {
    type: "spring",
    stiffness: 400,
    damping: 50,
  });
  const rotate = useTransform(scrollYProgress, position, [rotateStart, 0], {
    type: "spring",
    stiffness: 400,
    damping: 50,
  });

  return (
    <motion.div
      ref={ref}
      style={{
        opacity,
        y,
        rotate,
        x,
      }}
    >
      {children}
    </motion.div>
  );
};
