import "./style.css";

const list = [
    {
        image: "/assets/FutureVersion/Explore/1.png",
        name: "Fusion Industrial Program",
        description:
            "The program is ideal for third and fourth year students who want to get started with their professional career through our paid projects and gigs.",
    },
    {
        image: "/assets/FutureVersion/Explore/2.png",
        name: "Work Scholarship Program",
        description:
            "The program is for first and second year students who want to transform from a learner to a working professional through various educational experiences that focuses primarily on career discovery and enhancement.",
    },
    {
        image: "/assets/FutureVersion/Explore/3.png",
        name: "Young Leaders Accelerator Program",
        description:
            "The program is for first and second year students who want to transform from a learner to a working professional through various educational experiences that focuses primarily on career discovery and enhancement.",
    },
    {
        image: "/assets/FutureVersion/Explore/4.png",
        name: "Observership",
        description:
            "This program is recommended for all first year undergraduate students who want to get started with career and explore various industries and roles in a professional work environment.",
    },
];

function StudentProgram() {
    return (
        //Home Banner HTML
        <div className="py-8 inter w-full mx-auto">
            <div class="pt-10 pb-40 bg-student-program">
                <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                    <div class="flex flex-col text-center w-full justify-center items-center">
                        <h1 class="my-4 text-center text-3xl md:text-4xl font-bold leading-tight text-gray-800 poppins">
                            Student Programs
                        </h1>
                        <p class="leading-normal text-xl md:text-xl mb-8 text-gray-800 inter md:w-11/12">
                            Our student pathways ensure that we give an
                            office-like environment experience to students and
                            help them leverage their transferable skills
                        </p>
                        <div className="flex mt-3 flex-col items-center w-full space-y-5 lg:space-y-0 lg:flex-row lg:space-x-8">
                            <div className="w-full lg:w-1/3">
                                <img
                                    src="/assets/FutureVersion/Explore/studentProgram.png"
                                    className="max-h-80 w-full object-contain lg:max-h-screen"
                                    alt=""
                                />
                            </div>{" "}
                            <div className="w-full lg:w-2/3 flex flex-col items-stretch space-y-8">
                                {list?.map((item, index) => {
                                    return (
                                        <div className="student-program-card py-5 px-3 flex flex-row items-center space-x-8">
                                            <div className="w-20 h-20 bg-gray-100 rounded ml-3"></div>

                                            <div className="flex flex-col space-y-1.5 w-9/12">
                                                <h1 class="my-3 text-left font-bold leading-tight text-gray-800 poppins">
                                                    {item?.name}
                                                </h1>

                                                <p class="leading-normal text-left text-sm line-clamp-5 text-gray-800 inter">
                                                    {item?.description}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentProgram;
