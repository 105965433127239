import React from 'react';
// import { animated, useTransition } from "react-spring";
// import { FormGroup, Input, Label } from "reactstrap";
// import { Link } from "react-router-dom";

const offerings = [
	{
		id: 1,
		label: 'Career Kickstarter School',
		link: 'offerings/Career Kickstarter School',
	},
	{
		id: 2,
		label: 'Caarya Communities',
		link: '/offerings/caaryacommunities',
	},
	{ id: 3, label: 'Work-study program', link: '/offerings/caaryaworkstudy' },
	{ id: 4, label: 'Industry Safari', link: '/offerings/Industry Safari' },
];

const SelectOfferingModal = ({ isOpen, closeModal, onSelect }) => {
	// const [selectedOffering, setSelectedOffering] = React.useState(0);

	// const transition = useTransition(isOpen, {
	//   from: { x: 0, y: 400, opacity: 0 },
	//   enter: { x: 0, y: 0, opacity: 1 },
	//   leave: { x: 0, y: -400, opacity: 0 },
	// });
	return (
		<div>
			<div className="help-center-modal">
				<div></div>
				{/* {transition((style, item) =>
          item ? (
            <animated.div style={style} className="modal-container poppins">
              <div className="modal-body ">
                <img
                  src="/assets/AboutUs/close-modal.png"
                  className="float-right cursor-pointer"
                  onClick={closeModal}
                />
                <div className="poppins mt-10 flex flex-col mx-3">
                  <div
                    className=" text-2xl mt-1 font-semibold text-center"
                    style={{ color: "#6D1413", fontWeight: 500 }}
                  >
                    Select the offering you want to know more about -{" "}
                  </div>
                  <div className="help-center-form mt-6">
                    <div className=" ml-2 mt-2 text-lg fields">
                      {offerings.map((e, index) => (
                        <FormGroup className=" mt-2 ">
                          <Label>
                            <Input
                              type="radio"
                              color="#ED4C41"
                              onClick={() => setSelectedOffering(index)}
                              checked={selectedOffering === index}
                            />
                            <span className=" ml-2 text-base">{e.label}</span>
                          </Label>
                        </FormGroup>
                      ))}
                    </div>
                  </div>
                </div>
                <div className=" flex">
                  <Link
                    to={
                      selectedOffering != null &&
                      offerings[selectedOffering].link
                    }
                  >
                    <button class=" mt-4 ml-4 inter mx-auto lg:mx-0 hover:underline text-grey-800 font-bold rounded-full mb-12 sm:my-3 md:my-6 py-2 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out yellow-gradient">
                      View More
                    </button>
                  </Link>
                  <div
                    className=" ml-2"
                    onClick={() => {
                      onSelect(selectedOffering + 1);
                      closeModal();
                    }}
                  >
                    <button
                      style={{ borderWidth: 1, borderColor: "#FDBE5C" }}
                      class=" mt-4 ml-4 inter mx-auto lg:mx-0 hover:underline text-grey-800 font-bold rounded-full mb-12 sm:my-3 md:my-6 py-2 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                    >
                      Write a query
                    </button>
                  </div>
                </div>
              </div>
            </animated.div>
          ) : (
            ""
          )
        )} */}
			</div>
		</div>
	);
};

export default SelectOfferingModal;
