import React from "react";

function Stargaze() {
  return (
    <div className="bg-primary-stargaze-900">
      <div className="py-8 lg:py-20 px-4 max-w-7xl mx-auto flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-20 items-center justify-between">
        <img
          src="/assets/FutureVersion/startup/stargaze.png"
          alt=""
          className="mx-auto max-w-[172px] lg:max-w-[396px] h-auto"
        />{" "}
        <div className="py-6 flex flex-col space-y-10 text-white">
          <div className="flex flex-row items-center space-x-6">
            <img
              src="/assets/FutureVersion/startup/stargazer.png"
              alt=""
              className="h-12 w-12 object-cover"
            />
            <div className="w-0.5 bg-white h-12" />
            <h1 className="poppins stargaze-text text-[18px] lg:text-2xl font-semibold lg:*:leading-9 lg:tracking-[2.4px]">
              Stargazer
            </h1>
          </div>
          <h1 className="poppins text-2xl lg:text-[32px] font-light leading-9 tracking-[1.2px] lg:leading-[48px] lg:tracking-[3.2px] capitalize">
            Introducing The Stargazer
          </h1>
          <p className="inter text-sm lg:text-[18px] font-light leading-6 tracking-[0.7px] lg:leading-8 lg:tracking-[0.9px]">
            A unique tool that helps in <b>Cost Efficient Talent Discovery</b>.
            <br />
            <br /> We help filter the candidate pool based on your culture and
            value fit needs, ensuring a focused and resource-efficient
            recruitment process.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Stargaze;
