import FHomeBannerNav from "Components/Common/FHomeBannerNav";
import Footer from "Components/Common/Footer";
import HelpCenterContent from "Components/FutureVersion/HelpCenter/Content";
import HelpCenterCover from "Components/FutureVersion/HelpCenter/Cover";
import ReelFollowUs from "Components/FutureVersion/ReelComponents/ReelFollowUs";
import { useRef } from "react";

const FHelpCenter = () => {
  const contentRef = useRef();
  return (
    <div>
      <FHomeBannerNav />
      <HelpCenterCover
        onButtonClick={() =>
          contentRef.current.scrollIntoView({
            behavior: "smooth",
          })
        }
      />
      <div ref={contentRef}>
        <HelpCenterContent />
      </div>
      <ReelFollowUs bgColor={"red-gradient"} />
      <Footer />
    </div>
  );
};

export default FHelpCenter;
