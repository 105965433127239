import StudentsBanner from "Components/Students/Banner";
import Footer from "Components/Common/Footer";
import HomeBannerNav from "Components/Common/FHomeBannerNav";

import Programs from "Components/Students/Programs";
import StudentBenefits from "Components/Students/Benefits";
import Productivity from "Components/Students/Productivity";
import VirtualOffice from "Components/Students/VirtualOffice";
import Apply from "Components/Students/Apply";
import { useEffect } from "react";
import Loader from "Components/Common/Loader";
import { useRouteMatch } from "react-router-dom";
import Ikigai from "Components/Students/Ikigai";
import { initializeReactGA } from "App";
function FStudents() {
  const route = useRouteMatch();
  useEffect(() => {
    window.scrollTo({ top: 0 });
    initializeReactGA("Students Page");
  }, []);
  return (
    <div>
      {!localStorage.getItem(route?.path) && <Loader />}
      <HomeBannerNav backgroundColor={"red-plain"} />
      <StudentsBanner />
      <Programs />
      <Ikigai />
      <VirtualOffice /> <StudentBenefits />
      <Apply />
      <Footer />
    </div>
  );
}

export default FStudents;
