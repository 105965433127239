import React from "react";
import { MdClose } from "react-icons/md";

const SuccessModal = ({ isOpen, closeModal }) => {
  return (
    <div>
      <div className="help-center-modal">
        {isOpen && (
          <div className="modal-container poppins text-center">
            <div className="modal-body">
              <button onClick={closeModal}>
                <MdClose />
              </button>
              <img
                src="/assets/HelpCenter/sent.png"
                className=" mx-auto mt-5"
              />
              <div className="mt-6 mb-6 flex-col">
                <div
                  className=" font-semibold text-2xl text-center "
                  style={{ color: "#6D1413" }}
                >
                  Your message has been sent
                </div>
                <div className=" text-lg text-center mt-2">
                  Thank you! We will get back to you soon
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessModal;
