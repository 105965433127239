import "./style.css";

function CaaryaverseCover() {
    return (
        //Home Banner HTML
        <div className="py-8 inter w-full mx-auto">
            <div class="w-full pt-10 text-center md:hidden">
                <img
                    class="w-full mt-20"
                    src="/assets/FutureVersion/explorehero.png"
                />
            </div>
            <div class="pt-6 pb-10 md:pb-0">
                <div class="container px-4 md:px-20 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                    <div class="flex flex-col w-full md:w-1/2 justify-center items-center md:items-start text-center md:text-left">
                        <h1 class="my-4 mt-0 md:mt-32 text-3xl md:text-4xl font-bold leading-tight aqua-400 poppins">
                            Explore <span className="red-400">Caarya</span>
                        </h1>
                        <p class="leading-normal text-xl md:text-xl mb-8 text-gray-800 inter md:w-11/12">
                            It is never one size fits all. Explore an assortment
                            of learning experiences ranging from programs,
                            workshops, speaker sessions and events to earning
                            opportunities with an array of startups.
                        </p>
                    </div>
                    <div class="w-full md:w-1/2 md:py-24  text-center hidden md:block">
                        <img
                            class="w-5/6 mx-auto"
                            src="/assets/FutureVersion/explorehero.png"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaaryaverseCover;
